
import FlickityComponent from '@/components/ui/flickity.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Flickity: FlickityComponent,
  },
})
export default class SliderGallery extends Vue {
  @Prop({ default: () => [] }) resources!: any[];

  $refs!: {
    flickity: HTMLFormElement;
  };

  private currentIndex = 0;

  flickityOptions = {
    draggable: false,
    initialIndex: 0,
    autoPlay: false,
    pageDots: false,
    prevNextButtons: false,
    groupCells: 1,
    contain: true,
    cellAlign: 'left',
  };

  mounted() {
    this.$refs.flickity.on('change', () => {
      this.currentIndex = this.$refs.flickity.selectedIndex();
    });
    this.$refs.flickity.on('staticClick', (event: any, pointer: any, cellElement: any, cellIndex: any) => {
      if (cellElement) {
        this.$refs.flickity.selectCell(cellIndex);
      }
    });
  }

  protected updated() {
    this.$nextTick(() => this.$refs.flickity && this.$refs.flickity.reposition());
  }

  previous() {
    this.$refs.flickity.previous();
  }

  next() {
    this.$refs.flickity.next();
  }

  get currentImage() {
    return this.resources[this.currentIndex];
  }
}
