
import FilterBlocks from '@/components/ui/filter-blocks.vue';
import SortBy from '@/components/ui/form-sortby.vue';
import NoData from '@/components/ui/no-data.vue';
import Slider from '@/components/ui/slider.vue';
import { BannerType } from '@/enums/banner-type';
import { SortType } from '@/enums/sort-type';
import { SelectedTab } from '@/interfaces/selected-tab';
import Banner from '@/models/banner';
import Promotion from '@/models/promotion';
import { StateModel } from '@/models/state-model';
import BannerService from '@/services/banner';
import PromotionServices from '@/services/promotion';
import dayjs from 'dayjs';
import { take } from 'rxjs/operators';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

const COUNT_PER_PAGE = 4;
@Component({
  components: {
    FilterBlocks,
    Slider,
    SortBy,
    NoData,
  },
})
export default class Promotions extends Vue {
  @Ref('sortBy') sortByComponent!: SortBy;
  @Ref('blocks') blocksComponent!: FilterBlocks;
  bannerList: Banner[] = [];
  currentPage = 0;
  promotionList: Array<Promotion> = [];
  showTabs = false;
  private selectedTab = 0;
  private stateModel = new StateModel();
  private labels = [
    { text: 'Promotion.Recent', value: 0 },
    { text: 'Promotion.Expired', value: 1 },
  ];
  private defaultIndex = 0;
  private promotions: Promotion[] = [];

  scroll(ev: Event) {
    const isMounted = this.stateModel.isMounted;
    if (isMounted && window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.stateModel.loading) {
      this.loadPromotionByPage();
    }
  }

  async initialize() {
    this.promotions = [];
    const promotions = await PromotionServices.getPromotions(this.$route.params.lang, SortType.Default, true);
    this.promotions = promotions.filter((o) => dayjs(o.publishDate).isBefore(dayjs()));
    this.loadPromotionByPage();
    const bannerList = await BannerService.getBanners(this.$route.params.lang, BannerType.Promotion);
    this.bannerList = bannerList.map((x) => ({ ...x, imageMobile: x.imageDesktop }));
  }

  async sortChange(item: string) {
    const sortOptions = [
      { text: this.$t('Components.FormSortby.Default'), value: SortType.Default },
      { text: this.$t('Components.FormSortby.NewestOnTop'), value: SortType.NewestOnTop },
      { text: this.$t('Components.FormSortby.OldestOnTop'), value: SortType.OldestOnTop },
    ];
    const sortType = sortOptions.filter((o) => o.text === item)[0].value;

    this.promotionList = [];
    this.currentPage = 0;
    this.loadPromotionByPage(sortType);
  }

  formatDate(date: string) {
    return dayjs(date).format(this.$t('DateFormat') as string);
  }

  protected destroyed() {
    PromotionServices.clearSource();
  }

  @Watch('$route.params.lang', { immediate: true, deep: true })
  private async onUrlChange(newVal: Route) {
    try {
      this.promotionList = [];
      this.currentPage = 0;
      await this.initialize();

      const tabIndex = +this.$route.query.tabIndex;
      if (tabIndex) {
        this.selectTab(this.labels[tabIndex]);
        this.defaultIndex = tabIndex;
      } else {
        this.selectTab(this.labels[0]);
        this.defaultIndex = 0;
      }
      if (this.sortByComponent) {
        this.sortByComponent.reset();
      }
      this.showTabs = PromotionServices.isPromotionsExists;
    } catch (e) {
      console.error(e);
      this.$router.push(`/${this.$i18n.locale}/404`);
    }
  }

  private loadPromotionByPage(sortType: SortType = SortType.Default) {
    const isLoad = this.currentPage <= Math.ceil(this.promotionList.length / COUNT_PER_PAGE);
    const isPromotionReady = this.promotions.length > 0;
    if (isLoad && isPromotionReady) {
      this.stateModel.loading = true;
      this.stateModel.isMounted = true;
      let promotions = this.getPromotionByTab(this.selectedTab, [...this.promotions]);
      promotions.sort((a: Promotion, b: Promotion) => (dayjs(a.publishDate).isAfter(b.publishDate) ? -1 : 1));
      switch (sortType) {
        case SortType.NewestOnTop:
          break;
        case SortType.OldestOnTop:
          promotions.sort((a: Promotion, b: Promotion) => {
            return dayjs(a.publishDate).isBefore(b.publishDate) ? -1 : 1;
          });
          break;
        default:
          promotions = [...promotions.filter((o) => o.highlighted), ...promotions.filter((o) => !o.highlighted)];
          break;
      }
      const more = promotions.slice(this.currentPage * COUNT_PER_PAGE, (this.currentPage + 1) * COUNT_PER_PAGE);
      this.promotionList.push(...more);
      this.currentPage++;
      setTimeout(() => {
        this.stateModel.loading = false;
      }, 700);
    }
  }
  private getPromotionByTab(index: number, source: Array<Promotion>) {
    const now = dayjs().format('YYYY/MM/DD');
    if (index === 0) {
      return source.filter((x) => !dayjs(now).isAfter(x.endDate));
    } else {
      return source.filter((x) => dayjs(now).isAfter(x.endDate));
    }
  }

  private selectTab(ev: { text: string; value: any }) {
    this.selectedTab = ev.value;
    this.$router.replace({ name: 'promotion-list', query: { tabIndex: this.selectedTab.toString() } }).catch((e) => {});
    if (this.sortByComponent) {
      this.sortByComponent.reset();
    }
    this.promotionList = this.getPromotionByTab(ev.value, this.promotions).splice(0,COUNT_PER_PAGE);
  }
}
