import Game from '@/models/game';

const state: {
  games: Game[];
} = {
  games: [],
};

const service = {
  get() {
    return state;
  },
};

export default service;
