
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class FormInput extends Vue {
  @Prop() title!: string;
  @Prop() errorMsg!: string;
  @Prop() isRequired!: boolean;
  @Prop({ default: 'dark' }) theme!: 'dark' | 'light';
  @PropSync('inputValue') value!: string;
  @Prop({ default: 'text' }) type!: 'text' | 'password';
  @Prop({ default: 1000 }) maxLenth!: number;
}
