import { CategoriesHttpResponse } from '@/interfaces/http-response/get-categories';
import Category from '@/models/category';
import { CategoryAdapter } from '@/models/http-response-adapter/get-categories';
import { GetCategories } from '@/services/api';
import { BehaviorSubject } from 'rxjs';

class CategoryService {
  private categories = new BehaviorSubject<Category[]>([]);
  private categoriesError = new BehaviorSubject(false);
  private loadedCategories: Array<{ lang: string; categories: Category[] }> = [];

  get categories$() {
    return this.categories;
  }

  get categoriesError$() {
    return this.categoriesError;
  }

  async getCategories(lang: string) {
    if (this.loadedCategories.find((x) => x.lang === lang)) {
      this.categories.next(this.loadedCategories.find((x) => x.lang === lang)!.categories);
      return;
    }
    try {
      const categoriesResponse = await GetCategories<CategoriesHttpResponse>(lang);
      const categories = categoriesResponse.data.map((x, index) => CategoryAdapter.adapt(x, index));
      this.loadedCategories.push({ lang, categories });
      this.categories.next(categories);
    } catch (error) {
      this.categoriesError.next(true);
    }
  }
}

const service = new CategoryService();

export default service;
