import { SortType } from '@/enums/sort-type';
import { PromotionDetailHttpResponse } from '@/interfaces/http-response/get-promotion-detail';
import { PromotionsHttpResponse } from '@/interfaces/http-response/get-promotions';
import { PromotionDetailAdapter } from '@/models/http-response-adapter/get-promotion-detail';
import { PromotionAdapter } from '@/models/http-response-adapter/get-promotions';
import Promotion from '@/models/promotion';
import sort from '@alvarocastro/quicksort';
import { GetPromotions, GetPromotionsDetail } from './api';

import dayjs from 'dayjs';

class PromotionService {
  promotionRawSource!: PromotionsHttpResponse | null;

  get isPromotionsExists(): boolean {
    return this.promotionRawSource ? !!this.getPromotionsFromResponse(this.promotionRawSource).length : false;
  }

  async getPromotionDetail(id: string) {
    const promotionDetailResponse = await GetPromotionsDetail<PromotionDetailHttpResponse>(id);
    return PromotionDetailAdapter.adapt(promotionDetailResponse.data);
  }

  async getPromotions(lang: string, sortType: SortType = SortType.Default, reload = false): Promise<Promotion[]> {
    if (!this.promotionRawSource || reload) {
      const promotionResponse = await GetPromotions<PromotionsHttpResponse>(lang);
      this.promotionRawSource = promotionResponse.data;
      const promotions = this.getPromotionsFromResponse(promotionResponse.data);
      return this.sortingArticle(promotions, sortType);
    } else {
      const promotions = this.getPromotionsFromResponse(this.promotionRawSource);
      return this.sortingArticle(promotions, sortType);
    }
  }

  clearSource() {
    this.promotionRawSource = null;
  }

  private getPromotionsFromResponse(response: PromotionsHttpResponse): Array<Promotion> {
    return this.getVisible(response.map((x) => PromotionAdapter.adapt(x)));
  }

  private getVisible(promotions: Array<Promotion>): Array<Promotion> {
    return promotions.filter((o) => dayjs(o.publishDate).isBefore(dayjs()));
  }

  private sortingArticle(articles: Array<Promotion>, sortType: SortType = SortType.Default) {
    let sortedArticles = sort([...articles], (a: Promotion, b: Promotion) => {
      return a.publishDate === b.publishDate ? 0 : dayjs(a.publishDate).isAfter(b.publishDate) ? -1 : 1;
    });

    switch (sortType) {
      case SortType.NewestOnTop:
        return sortedArticles;
      case SortType.OldestOnTop:
        sortedArticles = sort([...articles], (a: Promotion, b: Promotion) => {
          return a.publishDate === b.publishDate ? 0 : dayjs(a.publishDate).isBefore(b.publishDate) ? -1 : 1;
        });
        return sortedArticles;
      default:
        return [...sortedArticles.filter((o: Promotion) => o.highlighted), ...sortedArticles.filter((o: Promotion) => !o.highlighted)];
    }
  }
}

const service = new PromotionService();

export default service;
