interface IPromotion {
  promotionId?: number;
  publishDate?: string;
  startDate?: string;
  endDate?: string;
  highlighted?: boolean;
  locale?: string;
  title?: string;
  subtitle?: string;
  thumbnail?: string;
}

export default class Promotion {
  promotionId = 0;
  publishDate = '';
  startDate = '';
  endDate = '';
  highlighted = false;
  locale = '';
  title = '';
  subtitle = '';
  thumbnail = '';
  constructor(promotion: IPromotion = {}) {
    this.promotionId = promotion.promotionId || 0;
    this.publishDate = promotion.publishDate || '';
    this.startDate = promotion.startDate || '';
    this.endDate = promotion.endDate || '';
    this.highlighted = promotion.highlighted || false;
    this.locale = promotion.locale || '';
    this.title = promotion.title || '';
    this.subtitle = promotion.subtitle || '';
    this.thumbnail = promotion.thumbnail || '';
  }
}
