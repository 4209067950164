
import { SelectedTab } from '@/interfaces/selected-tab';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Tabs extends Vue {
  @Prop({ default: [] }) private tabList!: string[];
  @Prop({ default: 'All' }) private defaultName!: string;
  private selectedIndex = 0;

  protected mounted() {
    this.selectTab();
  }

  private selectFromTab(index: number) {
    this.selectedIndex = index;
    this.selectTab();
  }

  private selectFromDropdown(name: string) {
    this.selectedIndex = this.tabList.indexOf(name);
    this.selectTab();
  }

  @Emit()
  private selectTab(): SelectedTab {
    return {
      index: this.selectedIndex,
      name: this.tabList[this.selectedIndex],
    };
  }
}
