interface INews {
  newsId?: number;
  tags?: string[];
  publishDate?: string;
  status?: 'visible' | 'disabled';
  highlighted?: boolean;
  title?: string;
  summary?: string;
  thumbnail?: string;
}

export default class News {
  newsId = 0;
  tags: string[] = [];
  publishDate = '';
  status: 'visible' | 'disabled' = 'disabled';
  highlighted = false;
  title = '';
  summary = '';
  thumbnail = '';
  constructor(news: INews = {}) {
    this.newsId = news.newsId || 0;
    this.tags = news.tags || [];
    this.publishDate = news.publishDate || '';
    this.status = news.status || 'disabled';
    this.highlighted = news.highlighted || false;
    this.title = news.title || '';
    this.summary = news.summary || '';
    this.thumbnail = news.thumbnail || '';
  }
}
