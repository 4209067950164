
import GameComponent from '@/components/common/game/game.vue';
import LoginModal from '@/components/common/modal/login.vue';
import NoData from '@/components/ui/no-data.vue';
import Game from '@/models/game';
import AuthService from '@/services/auth';
import ModalService from '@/services/modal';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { Game: GameComponent, NoData, LoginModal },
})
export default class GameList extends Vue {
  isAuthenticated = false;
  @Prop({ default: () => false }) private isLoading!: boolean;
  @Prop({ default: () => [] }) private games!: Game[];

  mounted() {
    AuthService.isAuthenticated$.subscribe((isLogin) => (this.isAuthenticated = isLogin));
  }

  checkAuth(gameId: string) {
    if (this.isAuthenticated) {
      this.goToDetailPage(gameId);
    } else {
      const data = {
        isGameList: true,
        action: () => this.goToDetailPage(gameId),
      };
      ModalService.open(LoginModal, { overlayCanceling: false, data });
    }
  }

  goToDetailPage(gameId: string) {
    this.$router.push({
      name: 'game-detail',
      params: {
        brand: this.$route.params.brand,
        category: this.$route.params.category,
        gameId,
        lang: this.$i18n.locale,
      },
      query: {
        search: this.$route.query.search,
        quickSearch: this.$route.query.quickSearch,
      },
    });
  }
}
