
import GeneralModal from '@/components/common/modal/general.vue';
import LoginModal from '@/components/common/modal/login.vue';
import FormButton from '@/components/ui/form-button.vue';
import FormSearch from '@/components/ui/form-search.vue';
import AuthService from '@/services/auth';
import ModalService from '@/services/modal';
import { filter, take } from 'rxjs/operators';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    FormSearch,
    FormButton,
    LoginModal,
  },
})
export default class Assets extends Vue {
  isAuthenticated = false;

  protected created() {
    AuthService.isAuthenticated$.subscribe((x) => (this.isAuthenticated = x));
  }

  protected mounted() {
    AuthService.isAuthenticated$
      .pipe(
        filter((x) => x),
        take(1)
      )
      .subscribe(() => {
        if (this.$screenDetect.isDesktop || this.$screenDetect.isDesktopHD) {
          return;
        }
        ModalService.open(GeneralModal, {
          data: {
            title: this.$t('Assets.DesktopOnlyModal.Title'),
            descriptions: this.$t('Assets.DesktopOnlyModal.Description'),
            type: 'Error',
          },
        });
      });
  }
}
