
import FormButton from '@/components/ui/form-button.vue';
import FormInput from '@/components/ui/form-input.vue';
import { User } from '@/interfaces/user';
import AuthService from '@/services/auth';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { Validation } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

@Component({
  components: {
    FormInput,
    FormButton,
  },
  validations: {
    formValue: {
      user: {
        username: {
          required,
        },
        password: {
          required,
        },
      },
    },
  },
})
export default class LoginModal extends Vue {
  @Prop({ default: () => false }) private isGameDetail!: boolean[];

  readonly data = {
    // check is in game list
    isGameList: false,
    // function pass fron game-list
    action: () => {},
  };
  private formValue = {
    user: {
      username: '',
      password: '',
    },
  };
  private error = {};

  get isInGamePage() {
    return this.isGameDetail || this.data.isGameList;
  }

  protected mounted() {
    this.resetError();
  }

  private async submit() {
    this.$v.$reset();
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.error = {
        username: ' ',
        password: this.$t('Modal.Login.Errors.RequiredField'),
      };
    } else {
      try {
        await AuthService.login(this.formValue.user);
        // force reload in the same page
        this.$router.replace({ name: this.$route.name || '', query: { cb: Date.now().toString() } });
        this.$emit('close');
        this.data.action();
      } catch (err) {
        switch (err.response && err.response.status) {
          case 400:
          case 401:
            this.error = {
              username: ' ',
              password: this.$t('Modal.Login.Errors.InvalidInput'),
            };
            break;
          case 500:
          default:
            this.error = {
              username: ' ',
              password: this.$t('Modal.Login.Errors.GeneralError'),
            };
            break;
        }
      }
    }
  }

  private contact() {
    this.$router.push(`/${this.$i18n.locale}/contact`);
    this.$emit('close');
  }

  private resetError() {
    this.error = {
      username: '',
      password: '',
    };
  }
}
