import Vue from 'vue';
import VueGtm from 'vue-gtm';
import router from '../router';

const isDevelop = process.env.NODE_ENV === 'development';

Vue.use(VueGtm, {
    // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
    id: 'GTM-MW4BRBX',
    // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results
    // (in case visitor leaves before script is loaded, which is unlikely but possible)
    defer: false,
    // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    enabled: true,
    // Whether or not display console logs debugs (optional)
    debug: isDevelop,
    // Whether or not to load the GTM Script (Helpful if you are including GTM manually,
    // but need the dataLayer functionality in your components) (optional)
    loadScript: true,
    // Pass the router instance to automatically sync with router (optional)
    vueRouter: router,
});
