var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ mobile: _vm.isMobile }},_vm._l((_vm.categories),function(category,i){return _c('div',{key:`Menu${i}`},[_c('div',{staticClass:"menu",class:{
        active: _vm.isBrandActive(category.name),
        toggle: _vm.isBrandToggled(category.name),
      },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.syncedSelectedCategory.toggleCategory(category), _vm.setCategory(category)}}},[(category.brand)?_c('img',{staticClass:"menu-logo",attrs:{"src":`/static/images/game/category-menu/${category.brand}.png`}}):_vm._e(),_c('span',{staticClass:"menu-title"},[_vm._v(_vm._s(category.name))]),(_vm.hasChildren(category))?_c('i',{staticClass:"icon icon-arrow-down"}):_vm._e()]),(_vm.hasChildren(category))?_c('div',{staticClass:"sub-menu-list",class:{
        active: _vm.isBrandToggled(category.name),
      }},_vm._l((category.categories),function(subCategory,i){return _c('div',{key:`SubMenus${i}`,staticClass:"sub-menu",class:{
          active: _vm.isSubCategoryActive(category, subCategory),
        },on:{"click":function($event){return _vm.setCategory(category, subCategory)}}},[(!_vm.isShowCategoryIcon(subCategory) && category.brand === 'MGLive')?_c('img',{staticClass:"menu-logo",attrs:{"src":`/static/images/game/category-menu/${category.brand}${_vm.isMobile ? '-b' : ''}.png`}}):_vm._e(),(_vm.isShowCategoryIcon(subCategory))?_c('img',{staticClass:"menu-logo",attrs:{"src":`/static/images/game/category-menu/MGLiveGrand${_vm.isMobile ? '-b' : ''}.png`}}):_vm._e(),_c('span',{staticClass:"subName"},[_vm._v(_vm._s(subCategory.name))])])}),0):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }