import { Game as GameData } from '@/interfaces/http-response/get-games';
import Game from '../game';

export class GameAdapter {
  static adapt(data: GameData): Game {
    return new Game({
      brand: data.brand,
      gameId: data.gameId,
      name: data.displayName,
      gameFeatures: data.gameFeatures,
      highlightedFeatures: data.highlightedFeatures,
      releaseDate: data.releaseDate,
      gameType: data.gameType,
      gameCode: data.gameCode,
      volatility: data.volatility,
    });
  }
}
