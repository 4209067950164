
import { HomeShortCut } from '@/interfaces/home/home-shortcuts';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class HomeShortCuts extends Vue {
  @Prop() shortcuts!: HomeShortCut[];

  getStyleClass(index: number) {
    return {
      top: index === 1,
    };
  }
}
