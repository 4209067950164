
import { handleScrollToId } from '@/plugins/scrollto';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GameFloatingBtn extends Vue {
  handleScrollToId = handleScrollToId;
  private isTop = true;
  protected mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }
  protected destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  private handleScroll() {
    const topSize = 1;
    this.isTop = window.pageYOffset < topSize;
  }
  private handleScrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
