
import Slider from '@/components/ui/slider.vue';
import Banner from '@/models/banner';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Slider,
  },
})
export default class FloatingButton extends Vue {
  @Prop({ default: () => [] }) private adList!: Banner[];

  private isHidden = false;
  private isClosed = false;
  private tagText = 'Hide';

  private toggle() {
    this.isHidden = !this.isHidden;
    setTimeout(() => {
      this.tagText = this.tagText === 'Hide' ? 'Expand' : 'Hide';
    }, 500);
  }

  private closeContainer() {
    this.isClosed = true;
  }
}
