interface StringMap {
  [key: string]: string;
}
export const languages: StringMap = {
  'en-us': 'EN',
  'hi-in': 'HI',
  'id-id': 'ID',
  'ja-jp': 'JA',
  'ko-kr': 'KO',
  'zh-cn': 'CN',
  'th-th': 'TH',
  'zh-tw': 'ZH',
  'vi-vn': 'VI',
  'te-in': 'TE',
  'ru-ru': 'RU',
  'pt-br': 'BR',
  'tr-tr': 'TR',
  'en-eu': 'EU',
};
