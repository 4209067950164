import dayjs from 'dayjs';
import Promotion from '../promotion';

export class PromotionAdapter {
  static adapt({
    promotionId,
    publishDate,
    startDate,
    endDate,
    status,
    highlighted,
    locale,
    title,
    subtitle,
    thumbnail,
  }: {
    promotionId: number;
    publishDate: string;
    startDate: string;
    endDate: string;
    status: string;
    highlighted: boolean;
    locale: string;
    title: string;
    subtitle: string;
    thumbnail: string;
  }): Promotion {
    return new Promotion({
      promotionId,
      publishDate: dayjs(publishDate).format('YYYY/MM/DD'),
      startDate: dayjs(startDate).format('YYYY/MM/DD'),
      endDate: dayjs(endDate).format('YYYY/MM/DD'),
      highlighted,
      locale,
      title,
      subtitle,
      thumbnail,
    });
  }
}
