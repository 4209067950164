
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NoData extends Vue {
  @Prop() private icon!: string;
  @Prop({ default: '' }) private iconColor!: 'red';
  @Prop() private title!: string;
  @Prop() private subTitle!: string;
}
