
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class FilterBlocksComponent extends Vue {
  @Prop() labels!: Array<{ text: string; value: any }>;
  @Prop() defaultIndex!: number;
  private activeIndex = 0;

  @Watch('defaultIndex', { immediate: true })
  onDefaultChange() {
    this.activeIndex = this.defaultIndex;
  }

  reset() {
    this.activeIndex = 0;
  }

  tabClicked(index: number) {
    this.activeIndex = index;
    this.$emit('click', this.labels[index]);
  }
}
