export const ScrollDirective = {
    bind(el: any, binding: any) {
        function documentHandler(e: any) {
            if (el.contains(e.target)) {
                return false;
            }
            if (binding.expression) {
                binding.value(e);
            }
        }
        el.__vueScroll__ = documentHandler;
        document.addEventListener('scroll', documentHandler);
    },
    unbind(el: any, binding: any) {
        document.removeEventListener('scroll', el.__vueScroll__);
        delete el.__vueScroll__;
    },
};
