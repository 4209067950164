import { defaultLang, localeSet } from '@/configs/lang';
import Lang from '@/layout/lang.vue';
import SharedLobbyLayout from '@/layout/lobby.vue';
import AuthService from '@/services/auth';
import { loadLanguageAsync } from '@/setup/i18n-setup';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const routes = [
  {
    path: '/:lang',
    component: Lang,
    children: [
      {
        path: '/',
        component: SharedLobbyLayout,
        children: [
          {
            path: '/',
            name: 'home',
            component: () => import('../pages/home.vue'),
            meta: { gtm: 'Home' },
          },
          {
            path: 'games/:brand?/:category?',
            name: 'game-list',
            component: () => import('../pages/games/games.vue'),
          },
          {
            path: 'game/:gameId',
            name: 'game-detail',
            props: true,
            component: () => import('../pages/games/game-detail.vue'),
            beforeEnter: (to: any, _: any, next: any) => {
              to.meta.isAuthenticated = AuthService.isAuthenticated;
              next();
            },
          },
          {
            path: 'news',
            name: 'news-list',
            component: () => import('../pages/news/news.vue'),
            meta: { gtm: 'News' },
          },
          {
            path: 'news/:id?',
            name: 'news-detail',
            component: () => import('../pages/news/news-detail.vue'),
          },
          {
            path: 'promotions',
            name: 'promotion-list',
            component: () => import('../pages/promotion/promotions.vue'),
            meta: { gtm: 'Promotions' },
          },
          {
            path: 'promotions/:id?',
            name: 'promotion-detail',
            component: () => import('../pages/promotion/promotion-detail.vue'),
          },
          // {
          //   path: 'contact',
          //   name: 'contact-us',
          //   component: () => import('../pages/contact.vue'),
          //   meta: { gtm: 'Contact' },
          // },
          {
            path: 'assets',
            name: 'assets',
            component: () => import('../pages/assets.vue'),
            meta: { gtm: 'Assets' },
          },
          {
            path: 'mg-reward',
            name: 'mg-reward',
            component: () => import('../pages/mg-reward.vue'),
            meta: { gtm: 'MG Rewards' },
          },
        ],
      },
      {
        path: '*',
        name: '404',
        component: () => import('../pages/not-found.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from.name === to.name) {
      return;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const token = to.query.token;
  if (token) {
    AuthService.oktaLogin(token);
  }

  const lang = localeSet.find((x) => !!to.params.lang && x.toLocaleLowerCase() === to.params.lang.toLocaleLowerCase());
  if (lang && lang !== to.params.lang) {
    next(to.path.replace(to.params.lang, lang));
  } else if (lang) {
    loadLanguageAsync(lang).then(() => next());
  } else {
    next(`/${defaultLang}`);
  }
});

router.afterEach((to, from) => {
  const path = to.path;
  const brand = to.params.brand;
  if (Vue.gtm && path.includes('games') && !!brand) {
    Vue.gtm.trackView(
      `Games - ${brand}`,
      path
    );
  }
});

export default router;
