
import FormButton from '@/components/ui/form-button.vue';
import { Component, Vue } from 'vue-property-decorator';

export interface GeneralModalData {
  type: '' | 'SubmitSuccessful' | 'SubmitFailed' | 'Error' | 'ComingSoon' | 'DownloadFailed';
  title?: string;
  descriptions?: string[];
}

@Component({
  components: {
    FormButton,
  },
})
export default class GeneralModal extends Vue {
  data: GeneralModalData = {
    type: '',
    title: '',
    descriptions: []
  };

  get descriptions() {
    return this.data.descriptions && this.data.descriptions.length ? this.data.descriptions : null;
  }

  private confirm() {
    this.$emit('close');
  }
}
