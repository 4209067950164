import Category, { SubCategory } from '@/models/category';

export class SelectedCategory {
  toggleMenu = '';
  icon = '';
  categoryBrand = {
    brand: '',
    name: '',
  };
  category: SubCategory = {
    name: '',
    games: [],
  };
  uniqueKey = '';
  selectedBrand = '';

  setCategory(category: Category, subCategory: SubCategory) {
    this.categoryBrand.brand = category.brand;
    this.categoryBrand.name = category.name;
    this.icon = category.icon;
    this.category = subCategory;
    this.uniqueKey = `${category.brand}-${subCategory.name}`;
    this.selectedBrand = category.name;
  }

  toggleCategory(category: Category) {
    this.toggleMenu = this.toggleMenu === category.name ? '' : category.name;
    this.selectedBrand = category.name;
  }

  hideCategory() {
    this.toggleMenu = '';
  }

  setDefault(category: Category, subCategory: SubCategory) {
    this.toggleMenu = category.name;
    this.setCategory(category, subCategory || category.categories[0]);
  }

  reset() {
    this.toggleMenu = '';
    this.icon = '';
    this.categoryBrand = {
      brand: '',
      name: '',
    };
    this.category = {
      name: '',
      games: [],
    };
    this.uniqueKey = '';
    this.selectedBrand = '';
  }
}
