
import GameComponent from '@/components/common/game/game.vue';
import Game from '@/models/game';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { Game: GameComponent },
})
export default class GameRecommendations extends Vue {
  @Prop({ default: () => [] }) private games!: Game[];

  goToDetailPage(gameId: string) {
    this.$router.push(`/${this.$i18n.locale}/game/${gameId}`);
  }
}
