
import CategoryMenu from '@/components/ui/category-menu.vue';
import Category, { SubCategory } from '@/models/category';
import { SelectedCategory } from '@/models/selected-category';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  components: {
    CategoryMenu,
  },
})
export default class CategorySection extends Vue {
  @Prop({ default: () => [] }) private readonly categories!: Category[];
  @PropSync('selectedCategory', { type: SelectedCategory })
  private syncedSelectedCategory!: SelectedCategory;
  private visible = false;

  setDropdown(e: any) {
    this.visible = !this.visible;
    if (this.visible) {
      window.scrollTo({
        top: e.target.getBoundingClientRect().y - 85 + window.pageYOffset,
        behavior: 'smooth',
      });
    }
  }

  hideDropdown() {
    this.visible = false;
  }
}
