
import GameList from '@/components/common/game/game-list.vue';
import CategorySection from '@/components/ui/category-section.vue';
import FormSearch from '@/components/ui/form-search.vue';
import FormSortby from '@/components/ui/form-sortby.vue';
import NoData from '@/components/ui/no-data.vue';
import PaginationComponent from '@/components/ui/pagination.vue';
import QuickTag from '@/components/ui/quick-tag.vue';
import Slider from '@/components/ui/slider.vue';
import { BannerType } from '@/enums/banner-type';
import { SortType } from '@/enums/sort-type';
import Banner from '@/models/banner';
import Category, { SubCategory } from '@/models/category';
import Game from '@/models/game';
import Pagination from '@/models/pagination';
import { SelectedCategory } from '@/models/selected-category';
import Tag from '@/models/tag';
import BannerService from '@/services/banner';
import CategoryService from '@/services/category';
import GameService from '@/services/game';
import TagService from '@/services/tag';
import dayjs from 'dayjs';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, filter, first, map, startWith } from 'rxjs/operators';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component({
  components: {
    CategorySection,
    FormSearch,
    FormSortby,
    GameList,
    Slider,
    NoData,
    Pagination: PaginationComponent,
    QuickTag,
  },
  subscriptions() {
    const $searchText = this.$watchAsObservable('searchText').pipe(startWith(''));
    const gameWithSub: { [key: string]: string } = {};
    return {
      combineGameWithCategory: combineLatest([GameService.games$, CategoryService.categories$, $searchText]),
      games: combineLatest([GameService.games$, CategoryService.categories$]).pipe(
        map(([games, categories]) => {
          const subCategories = categories
            .filter((data: any) => {
              if (data.brand !== 'TopGames' && data.brand !== 'AllGames') {
                return true;
              }
              return false;
            })
            .map((data: any) => data.categories);
          subCategories.flat().map((data: any) => {
            data.games.map((id: string) => {
              gameWithSub[id] = data.name;
            });
          });
          return games.map((game: Game) => {
            if (gameWithSub[game.gameId]) {
              game.subCategory = gameWithSub[game.gameId];
            }
            return game;
          });
        })
      ),
      gamesError: GameService.gamesError$,
      categories: CategoryService.categories$,
      categoriesError: CategoryService.categoriesError$,
      tags: TagService.tag$,
      tagsError: TagService.tagsError$,
      gamesByTag: GameService.gamesByTag$,
    };
  },
})
export default class Games extends Vue {
  @Ref('sortBy') sortByComponent!: FormSortby;
  games: Game[] = [];
  bannerList: Banner[] = [];
  searchText = '';
  selectedCategory = new SelectedCategory();
  categories: Category[] = [];
  subscriptions: Subscription = new Subscription();
  filterGames: Game[] = [];
  sortType: SortType = SortType.NewestOnTop;
  pagination = new Pagination({ totalRecoreds: this.filterGames.length });
  tags: Tag[] = [];
  isShowQuickSearchGame = false;
  quickSearchSelectedValue = '';
  gamesByTag: Game[] = [];
  isLoading = false;

  search(text: string) {
    this.searchText = text;
    if ((this.$route.query.search as string) !== text) {
      delete this.$route.query.quickSearch;
      this.$router.replace({ query: { ...this.$route.query, search: text } });
    }
  }

  protected async mounted() {
    const text = this.$route.query.search as string;
    if (text) {
      (this.$refs.searchField as FormSearch).setText(text);
      this.searchText = text;
    }

    const sub = this.$observables.combineGameWithCategory.pipe(debounceTime(200)).subscribe(async ([game, categories, searchText]) => {
      if (categories.length !== 0) {
        await this.setCategory((category, subCategory) => {
          this.selectedCategory.setDefault(category, subCategory);
          this.filteredGames();
        });
      }
    });

    const tags = this.$observables.tags
      .pipe(
        filter((x) => x.length > 0),
        first()
      )
      .subscribe(async (tag) => {
        const found = this.tags.find((x) => x.name === this.quickSearchSelectedValue);
        if (!found) {
          this.gamesByTag = [];
          return;
        }
        this.isLoading = true;
        await GameService.getAllGamesByTag(this.quickSearchSelectedValue, this.$i18n.locale);
      });

    const gameSub = this.$observables.gamesByTag.pipe().subscribe(async (games) => {
      this.filteredGames();
      this.isLoading = false;
    });

    this.subscriptions.add(sub);
    this.subscriptions.add(tags);
    this.subscriptions.add(gameSub);
    await this.loadBanner();

    this.pagination.totalRecords = this.filterGames.length;
  }

  protected destroyed() {
    this.subscriptions.unsubscribe();
  }

  @Watch('$route.params.lang', { immediate: true })
  private async localeChange(to: Route, from: Route) {
    await this.loadBanner();
    if (this.sortByComponent) {
      this.sortType = this.selectedCategory.categoryBrand.brand === 'TopGames' ? SortType.Default : SortType.NewestOnTop;
      this.sortByComponent.setSelectedItem(this.sortType);
    }
  }

  @Watch('$route.query.search', { immediate: true })
  private searchChange(to: Route, from: Route) {
    const text = this.$route.query.search as string;
    if (text) {
      const searchField = this.$refs.searchField as FormSearch;
      if (searchField) {
        searchField.setText(text);
        this.search(text);
      }
    }
  }

  @Watch('$route.query.quickSearch', { immediate: true })
  private async quickSearchChange(to: Route, from: Route) {
    const text = this.$route.query.quickSearch as string;
    this.quickSearchSelectedValue = text;
    if (!text) {
      this.isShowQuickSearchGame = false;
      return;
    }
    this.isShowQuickSearchGame = true;
  }

  get searchableGamesList(): Array<string> {
    return this.categories
      .map((o) => o.categories)
      .map((category) => category.map((o) => o.games))
      .reduce((accumlator, currentValue) => [...accumlator, ...currentValue], [])
      .reduce((accumlator, currentValue) => [...accumlator, ...currentValue], []);
  }

  get gamesList() {
    let res: Game[] = [];
    const sortName = (a: Game, b: Game) => {
      const numA = a.name.match(/\d+$/);
      const numB = b.name.match(/\d+$/);
      if (numA && numB) {
        return Number(numA[0]) - Number(numB[0]);
      }

      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    };
    const filterGames = [...this.filterGames];
    switch (this.sortType) {
      case SortType.Default:
        res = filterGames;
        break;
      case SortType.NewestOnTop:
        res = filterGames.sort((a: Game, b: Game) => {
          if ((a.releaseDate === '' && b.releaseDate !== '') || dayjs(a.releaseDate).isBefore(b.releaseDate)) {
            return 1;
          }
          if ((a.releaseDate !== '' && b.releaseDate === '') || dayjs(a.releaseDate).isAfter(b.releaseDate)) {
            return -1;
          }
          return sortName(a, b);
        });
        break;
      case SortType.OldestOnTop:
        res = filterGames.sort((a: Game, b: Game) => {
          if ((a.releaseDate !== '' && b.releaseDate === '') || dayjs(a.releaseDate).isBefore(b.releaseDate)) {
            return -1;
          }
          if ((a.releaseDate === '' && b.releaseDate !== '') || dayjs(a.releaseDate).isAfter(b.releaseDate)) {
            return 1;
          }
          return sortName(a, b);
        });
        break;
    }
    this.pagination.totalRecords = res.length;
    return this.getGamesByPageIndex(res);
  }

  filteredGames() {
    this.pagination.currentPage = 1;
    if (this.isShowQuickSearchGame) {
      this.filterGames = this.gamesByTag;
      return;
    }
    if (!this.games || !this.categories) {
      this.filterGames = [];
      return;
    }
    const searchableGames = this.games.filter((game: Game) => this.searchableGamesList.indexOf(game.gameId) !== -1);
    // search
    if (this.searchText.length >= 2) {
      this.selectedCategory.hideCategory();
      const searchRules = [
        (x: Game) => x.name.toLowerCase().includes(this.searchText.toLowerCase()),
        (x: Game) => x.gameCode.toLowerCase().includes(this.searchText.toLowerCase()),
        (x: Game) => x.gameType.toLowerCase().includes(this.searchText.toLowerCase()),
        (x: Game) => x.gameFeatures.some((text) => text.toLowerCase().includes(this.searchText.toLowerCase())),
        (x: Game) => x.highlightedFeatures.some((text) => text.toLowerCase().includes(this.searchText.toLowerCase())),
        (x: Game) => x.volatility.toLowerCase().includes(this.searchText.toLowerCase()),
      ];
      this.filterGames = searchableGames.filter((x) => searchRules.reduce((result: boolean, searchRule) => result || searchRule(x), false));
      return;
    }

    // category
    const gameIdList = this.selectedCategory.category.games;
    if (gameIdList.length !== 0) {
      this.filterGames = gameIdList.map((x) => this.games.find((game) => game.gameId === x)) as Game[];
      return;
    }
    this.filterGames = [];
  }

  async sortGames(item: string) {
    const sortOptions = [
      { text: this.$t('Components.FormSortby.Default'), value: SortType.Default },
      { text: this.$t('Components.FormSortby.NewestOnTop'), value: SortType.NewestOnTop },
      { text: this.$t('Components.FormSortby.OldestOnTop'), value: SortType.OldestOnTop },
    ];
    this.sortType = sortOptions.filter((o) => o.text === item)[0].value;
  }

  private async loadBanner() {
    this.bannerList = [];
    const bannerList = await BannerService.getBanners(this.$route.params.lang, BannerType.Game);
    this.bannerList = bannerList.map((x) => ({ ...x, imageMobile: x.imageDesktop }));
  }

  private async onCategoryChange() {
    this.clearSearchText();
    await this.setCategory((category, subCategory) => {
      this.selectedCategory.setCategory(category, subCategory);
    });

    this.pagination.currentPage = 1;
  }

  private async setCategory(setMethod: (category: Category, subCategory: SubCategory) => void) {
    const addSearchQuery = (): string => {
      let query = '';
      if (this.$route.query && this.$route.query.search) {
        query = `?search=${this.$route.query.search}`;
      }
      return query;
    };
    const categories = this.categories;
    if (categories.length === 0) {
      return;
    }
    const brandName = this.$route.params.brand || '';
    const categoryName = this.$route.params.category || '';
    const category = this.categories.find((x) => x.brand === brandName) || this.categories[2];
    const subCategory = (category && category.categories.find((x) => x.name === categoryName)) || category!.categories[0];
    this.sortType = category.brand === 'TopGames' ? SortType.Default : SortType.NewestOnTop;
    if (this.sortByComponent) {
      this.sortByComponent.setSelectedItem(this.sortType);
    }
    if ((category.brand !== brandName || subCategory.name !== categoryName) && this.$i18n) {
      try {
        await this.$router.replace(`/${this.$i18n.locale}/games/${category.brand}/${subCategory.name}${addSearchQuery()}`);
      } catch (e) { }
    }
    setMethod(category, subCategory);
  }

  private getGamesByPageIndex(games: Array<Game>) {
    const range = this.pagination.range();
    return games.slice(range.from, range.to);
  }

  async setQuickSearch(tag: Tag) {
    this.clearSearchText();
    const brandName = this.$i18n.locale === 'zh-CN' ? '所有游戏' : 'All Games';
    if (this.$i18n) {
      try {
        await this.$router.replace(`/${this.$i18n.locale}/games/AllGames/${brandName}?quickSearch=${tag.name}`);
      } catch (e) { }
    }
    this.isLoading = true;
    await GameService.getAllGamesByTag(tag.name, this.$i18n.locale);
  }

  clearSearchText() {
    this.searchText = '';
    (this.$refs.searchField as FormSearch).clear();
    const query = { ...this.$route.query };
    if (query.search) {
      delete query.search;
      this.$router.replace({ query });
    }
  }
}
