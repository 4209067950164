export enum EventCategory {
    GamePage = 'Game Details page',
    NewsPage = 'News Article',
    PromotionsPage = 'Promotions Article',
}

export enum EventAction {
    View = 'View',
    Click = 'Click',
}

export const EventLabel = {
    GameDetailsPageView: (gameCode: string) => `GameDetails_PageView_${gameCode}`,
    GameDetailsClickPlayNow: (gameCode: string) => `GameDetails_Click_PlayNow_${gameCode}`,
    GameDetailsClickDownload: (gameCode: string) => `GameDetails_Click_Download_${gameCode}`,
    NewsArticlePageView: (articleName: string) => `NewsArticle_PageView_${articleName.substring(0, 25)}`,
    PromotionsArticlePageView: (articleName: string) => `PromotionsArticle_PageView_${articleName.substring(0, 25)}`,
};
