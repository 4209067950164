import { AdvertisementHttpResponse } from '@/interfaces/http-response/get-advertisements';
import { AdvertisementAdapter } from '@/models/http-response-adapter/get-advertisements';
import { GetAdvertisements } from './api';

class AdvertisementService {
    async getAdvertisements(lang: string) {
        const adsResponse = await GetAdvertisements<AdvertisementHttpResponse>(lang);
        return adsResponse.data.map((x) => AdvertisementAdapter.adapt(x));
    }
}

const service = new AdvertisementService();

export default service;
