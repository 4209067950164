// ref: https://github.com/view-design/ViewUI/blob/master/src/directives/clickoutside.js
export default {
    bind(el: any, binding: any, vnode: any) {
        function documentHandler(e: any) {
            if (el.contains(e.target)) {
                return false;
            }
            if (binding.expression) {
                binding.value(e);
            }
        }
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    unbind(el: any, binding: any) {
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};
