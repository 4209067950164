
import Footer from '@/components/common/footer.vue';
import GameFloatingBtn from '@/components/common/game/game-floating-btn.vue';
import Header from '@/components/common/header.vue';

export default {
  name: 'lobby',
  components: {
    Header,
    Footer,
    GameFloatingBtn,
  },
};
