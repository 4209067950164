
import FloatButton from '@/components/common/floating-button.vue';
import HomePromtion from '@/components/common/home/promotion.vue';
import HomeSection from '@/components/common/home/section.vue';
import HomeShortCuts from '@/components/common/home/shortcuts.vue';
import LoginModal from '@/components/common/modal/login.vue';
import FlickityComponent from '@/components/ui/flickity.vue';
import FormButton from '@/components/ui/form-button.vue';
import Slider from '@/components/ui/slider.vue';
import { moreGameConfigByLocale } from '@/configs/more-game';
import { BannerType } from '@/enums/banner-type';
import { HomeShortCut } from '@/interfaces/home/home-shortcuts';
import Banner from '@/models/banner';
import Game from '@/models/game';
import { ScreenType } from '@/plugins/screen-detect';
import AdvertisementService from '@/services/advertisement';
import BannerService from '@/services/banner';
import CategoryService from '@/services/category';
import GameService from '@/services/game';
import ModalService from '@/services/modal';
import { combineLatest } from 'rxjs';
import { filter, map, merge, mergeMap, take } from 'rxjs/operators';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component({
  components: {
    Slider,
    HomeShortCuts,
    HomeSection,
    HomePromtion,
    FormButton,
    Flickity: FlickityComponent,
    FloatButton,
  },
  subscriptions() {
    const getTopGames = (_brand: string, _top = 18) => {
      return combineLatest([
        GameService.games$.pipe(map((games) => games.filter((game) => game.brand === _brand))),
        CategoryService.categories$.pipe(map((categories) => categories.find((category) => category.brand === _brand))),
      ]).pipe(
        map(([game, category]) => {
          if (category && category.categories) {
            return category!.categories[0].games.slice(0, _top).map((gameId) => {
              return game.find((item) => item.gameId === gameId);
            });
          }
        })
      );
    };

    const getGames = (_displayName: string[], _top = 18) => {
      return combineLatest([
        GameService.games$,
        CategoryService.categories$.pipe(map((categories) => categories.find((category) => category.brand === 'Microgaming'))),
      ]).pipe(
        map(([game, category]) => {
          if (category && category.categories) {
            const getCategories = category.categories.filter((item) => {
              return _displayName.some((name) => item.name.trim() === name);
            })[0];
            if (!getCategories) {
              return [];
            }
            return getCategories.games.slice(0, _top).map((gameId) => {
              return game.find((item) => item.gameId === gameId);
            });
          }
        })
      );
    };
    return {
      mgGameList: getTopGames('Microgaming'),
      liveGameList: getGames(['真人', 'Live', '라이브 게임']),
    };
  },
})
export default class Home extends Vue {
  $refs!: {
    flickity: HTMLFormElement;
  };
  bannerList: Banner[] = [];
  brandBannerListFetched: Banner[] = [];
  brandBannerList: any = [];
  brandBannerListKeyForRerender = 0;
  adList: Banner[] = [];
  mgGameList: Game[] = [];
  liveGameList: Game[] = [];
  flickityOptions = {
    autoPlay: 5000,
    initialIndex: 0,
    prevNextButtons: false,
    pageDots: true,
    wrapAround: true,
    pauseAutoPlayOnHover: false,
    draggable: true,
    adaptiveHeight: true,
    imagesLoaded: true,
  };
  shortcuts: HomeShortCut[] = [
    {
      link: '/news',
      image: 'home/shortcut-news.png',
      title: 'Components.Header.News',
    },
    {
      link: '/games/LatestGames',
      image: 'home/shortcut-game.png',
      title: 'Components.Shortcut.LatestGames',
    },
    {
      link: '/promotions',
      image: 'home/shortcut-promotions.png',
      title: 'Components.Header.Promotions',
    },
  ];
  // set default as en-US will update latter
  moreGameConfig = moreGameConfigByLocale('en-US');
  screenType: ScreenType = {} as ScreenType;

  @Watch('$route', { immediate: true, deep: true })
  private async onUrlChange(newVal: Route) {
    this.bannerList = [];
    this.bannerList = await BannerService.getBanners(this.$route.params.lang, BannerType.Home);
    this.brandBannerListFetched = [];
    this.brandBannerListFetched = await BannerService.getBanners(this.$route.params.lang, BannerType.BrandBanner);
    this.adList = [];
    this.adList = await AdvertisementService.getAdvertisements(this.$route.params.lang);
    // update when locale changed
    this.setup();
  }

  protected mounted() {
    this.$screenDetect.resize$.subscribe((detect: ScreenType) => {
      this.screenType = detect;
      this.setup();
    });
  }

  protected updated() {
    this.$nextTick(() => this.$refs.flickity && this.$refs.flickity.reposition());
  }

  private setup() {
    if (this.brandBannerListFetched) {
      this.brandBannerList = this.brandBannerListFetched.map((item) => {
        return {
          image: this.screenType.isMobile ? item.imageMobile : item.imageDesktop,
          ...item,
        };
      });
      this.brandBannerListKeyForRerender++;
    }
  }

  private goToUrl(url: string) {
    if (!url) {
      return;
    }
    window.open(/^http(s?)(.*)$/.test(url) ? url : `http://${url}`, '_blank');
  }

  public getLiveString(lang: string) {
    return lang === 'zh-CN' ? '真人' : lang === 'ko-KR' ? '라이브%20게임' : 'Live';
  }
}
