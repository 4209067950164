import dayjs from 'dayjs';
import News from '../news';

export class NewsAdapter {
  static adapt({
    newsId,
    tags,
    publishDate,
    highlighted,
    status,
    title,
    summary,
    thumbnail,
  }: {
    newsId: number;
    tags: string[];
    publishDate: string;
    status: 'visible' | 'disabled';
    highlighted: boolean;
    title: string;
    summary: string;
    thumbnail: string;
  }): News {
    const SUMMARY_MAX_LENGTH = 70;
    if (summary.length > SUMMARY_MAX_LENGTH) {
      summary = `${summary.substr(0, SUMMARY_MAX_LENGTH)}...`;
    }
    return new News({
      newsId,
      tags,
      publishDate: dayjs(publishDate).format('YYYY/MM/DD'),
      highlighted,
      status,
      title,
      summary,
      thumbnail,
    });
  }
}
