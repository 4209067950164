
import FormButton from '@/components/ui/form-button.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    FormButton,
  },
})
export default class NotFound extends Vue {
  private backHome() {
    this.$router.push(`/${this.$i18n.locale}`);
  }
}
