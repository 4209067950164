import dayjs from 'dayjs';
import PromotionDetail, { Content } from '../promotion-detail';

export class PromotionDetailAdapter {
  static adapt({
    publishDate,
    startDate,
    endDate,
    highlighted,
    locale,
    title,
    subtitle,
    thumbnail,
    content,
  }: {
    publishDate: string;
    startDate: string;
    endDate: string;
    highlighted: boolean;
    locale: string;
    title: string;
    subtitle: string;
    thumbnail: { imageId: string; imagePath: string; };
    content: Array<Content>;
  }): PromotionDetail {
    const SUBTITLE_MAX_LENGTH = 70;
    if (subtitle.length > SUBTITLE_MAX_LENGTH) {
      subtitle = `${subtitle.substr(0, SUBTITLE_MAX_LENGTH)}...`;
    }
    return new PromotionDetail({
      publishDate: dayjs(publishDate).format('YYYY/MM/DD'),
      startDate: dayjs(startDate).format('YYYY/MM/DD'),
      endDate: dayjs(endDate).format('YYYY/MM/DD'),
      locale,
      title,
      subtitle,
      thumbnail,
      content: content.map((x) => new Content(x.image, x.paragraph, x.blockType)),
    });
  }
}
