
import GameFloatingBtn from '@/components/common/game/game-floating-btn.vue';
import GameRecommendations from '@/components/common/game/game-recommendations.vue';
import GameIcon from '@/components/common/game/game.vue';
import SliderGallery from '@/components/common/game/slider-gallery.vue';
import GeneralModal, { GeneralModalData } from '@/components/common/modal/general.vue';
import IframeModal, { IframeModalData } from '@/components/common/modal/iframe.vue';
import LoginModal from '@/components/common/modal/login.vue';
import Popover from '@/components/common/popover.vue';
import FormButton from '@/components/ui/form-button.vue';
import { Locale } from '@/enums/lang';
import { ScreenShot } from '@/interfaces/http-response/get-specific-game';
import Game from '@/models/game';
import SpecificGame from '@/models/specific-game';
import { handleScrollToId } from '@/plugins/scrollto';
import AuthService from '@/services/auth';
import GameService from '@/services/game';
import GameDetailService from '@/services/game-detail';
import LoadingService, { LoadingEntity } from '@/services/loading';
import ModalService from '@/services/modal';
import ScrollService from '@/services/scroll';
import dayjs from 'dayjs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventAction, EventCategory, EventLabel } from '../../configs/gtm';
import { gameBackground } from './game-background-config';
import { languages } from './language.config';

enum InformationStyle {
  Default,
  Block,
  Slash,
  List,
  Search,
  BetLimits,
  GameMechanics,
}

@Component({
  components: {
    LoginModal,
    GameIcon,
    FormButton,
    GameRecommendations,
    GameFloatingBtn,
    SliderGallery,
    Popover,
  },
})
export default class GameDetailComponent extends Vue {
  @Prop() brand!: string;
  @Prop() category!: string;

  isAuthenticated = false;
  gameId = '';
  games: Game[] = [];
  specificGame = new SpecificGame();
  styleType = InformationStyle;
  InformationList = [
    { label: 'GameType', key: 'gameType', type: InformationStyle.Search },
    { label: 'TheoreticalRTP', key: 'theoreticalRTP' },
    { label: 'MaxExposure', key: 'gameMechanics', item: 'maxExposure', type: InformationStyle.GameMechanics },
    { label: 'BetLimits', key: 'gameMechanics', item: 'betLimits', type: InformationStyle.GameMechanics },
    { label: 'Volatility', key: 'volatility', type: InformationStyle.Search },
    { label: 'Lines', key: 'gameMechanics', item: 'lines', type: InformationStyle.GameMechanics },
    { label: 'Ways', key: 'gameMechanics', item: 'ways', type: InformationStyle.GameMechanics },
    { label: 'Reels', key: 'gameMechanics', item: 'reels', type: InformationStyle.GameMechanics },
    { label: 'GameSize', key: 'gameMechanics', item: 'gameSizeInMb', type: InformationStyle.GameMechanics },
    { label: 'Theme', key: 'themes', type: InformationStyle.Block },
    { label: 'SuggestedTableBetLimits', key: 'ldBetLimits', item: 'player', type: InformationStyle.BetLimits },
    { label: 'SuggestedBankerBetLimits', key: 'ldBetLimits', item: 'table', type: InformationStyle.BetLimits },
    { label: 'SuggestedTripleBetLimits', key: 'ldBetLimits', item: 'triple', type: InformationStyle.BetLimits },
    { label: 'Language', key: 'languages', field: 'launchLangCode' },
    { label: 'GameFeatures', key: 'gameFeatures', type: InformationStyle.List, images: 'gameFeatureLabels' },
    { label: 'HighlightedFeatures', key: 'highlightedFeatures', type: InformationStyle.List },
  ];

  isCollapse = false;
  videoUrl: ScreenShot | null = null;
  gameSliders: ScreenShot[] = [];
  downloadAssetLoading = {} as LoadingEntity;
  isShowFlags = false;
  handleScrollToId = handleScrollToId;
  showLogo = false;
  dropdownOpen = false;

  navConfig = [
    { label: 'Game.Specifications', anchor: 'specifications' },
    { label: 'Game.Assets', anchor: 'assets' },
    { label: 'Game.Intro', anchor: 'intro' },
    { label: 'Game.Video', anchor: 'video' },
    { label: 'Game.HighLights', anchor: 'highlights' },
  ];

  navList = [...this.navConfig];
  moreNavList: any[] = [];
  activeAnchor = this.navConfig[0];
  nav: any[] = [];
  navMore: any[] = [];
  navWihtLogo: any[] = [];
  navMoreWithLogo: any[] = [];
  lastScrollTop = 0;

  get isZH() {
    return this.$i18n.locale === 'zh-CN';
  }

  resetState() {
    this.dropdownOpen = false;
  }

  isValidDate(date: string) {
    return dayjs(date).isValid();
  }

  formatDate(date: string) {
    return dayjs(date).format(this.$t('DateFormat') as string);
  }

  protected async mounted() {
    AuthService.isAuthenticated$.subscribe((isLogin) => {
      this.isAuthenticated = isLogin;
    });
    this.loadGamedetailData();
    LoadingService.isLoading$.subscribe((data) => {
      this.downloadAssetLoading = data;
    });
    window.addEventListener('scroll', this.handleScroll);
  }

  protected destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private async loadGamedetailData() {
    this.gameId = this.$route.params.gameId;
    if (!this.isAuthenticated || !this.gameId) {
      return;
    }
    try {
      this.specificGame = new SpecificGame();
      LoadingService.setInitLoading(true);
      const specificGame = await GameService.getSpecificGame(this.$route.params.lang, this.gameId);
      this.gameSliders = [];
      this.$nextTick(() => {
        this.specificGame = specificGame;
        this.videoUrl = specificGame.screenshots.find((x) => x.scheme === 'video') || null;
        this.gameSliders = specificGame.screenshots.filter((x) => x.scheme === 'image');
        this.getNavList();
        LoadingService.setInitLoading(false);
        this.gameDetailsPageView(this.specificGame.gameCode);
      });
    } catch (e) {
      console.error(e);
    }
    this.games = [];
    this.games = await GameDetailService.getRecommendGames(this.gameId, this.$route.params.lang);
  }

  private gameDetailsPageView(gameCode: string) {
    this.$gtm.trackEvent({
      event: `idvGamePageView`,
      category: EventCategory.GamePage,
      action: EventAction.View,
      label: EventLabel.GameDetailsPageView(gameCode),
    });
  }

  private async getDownloadAssets(tag?: string) {
    const isBlockDownload = this.$screenDetect.isMobile;
    if (isBlockDownload) {
      ModalService.open<GeneralModalData>(GeneralModal, {
        data: { type: 'DownloadFailed' },
        overlayCanceling: false,
      });
    } else {
      try {
        LoadingService.setLoading(true);
        const dowloadAssets = await GameDetailService.getDownloadAssets(this.gameId, tag);
        LoadingService.setLoading(false);
        ModalService.open<IframeModalData>(IframeModal, {
          data: dowloadAssets,
          width: '90%',
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  async launchGame(lang: Locale) {
    await this.specificGame.game.launch(this.gameId, lang, this.device, this.specificGame.gameMechanics.isDemoPlay);
    this.$gtm.trackEvent({
      event: `idvGameLaunch`,
      category: EventCategory.GamePage,
      action: EventAction.Click,
      label: EventLabel.GameDetailsClickPlayNow(this.specificGame.gameCode),
    });
  }

  private get locale() {
    return this.$route.params.lang as Locale;
  }

  private get device() {
    return this.$screenDetect.isMobile ? 'mobile' : 'desktop';
  }

  private backGame(brand: string) {
    if (brand) {
      this.$router.push(`/${this.$i18n.locale}/games/${this.brand}/${this.category}`);
    } else {
      this.$router.push(`/${this.$i18n.locale}/games/AllGames`);
    }
  }

  private get assetsDownload() {
    return this.specificGame.downloadOptions.filter((item) => item.name !== 'Fast_Pack');
  }

  private get fastPack() {
    return this.specificGame.downloadOptions.some((item) => item.name === 'Fast_Pack');
  }

  get background() {
    const brand = this.specificGame.brand as string;
    const url = gameBackground[brand] || gameBackground.Microgaming;
    return brand ? `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${url})` : '';
  }

  @Watch('$route')
  private onRouteChange() {
    this.$nextTick(() => {
      ScrollService.scrollToTop();
    });
    this.nav = [...this.navConfig];
    this.activeAnchor = this.navConfig[0];
    this.loadGamedetailData();
  }

  search(text: string) {
    const name = this.$i18n.locale === 'zh-CN' ? '所有游戝' : 'All Games';
    this.$router.push(`/${this.$i18n.locale}/games/Microgaming/${name}?search=${text}`);
  }

  switchFlags() {
    this.isShowFlags = !this.isShowFlags;
  }

  scrollToElement(item: any) {
    this.activeAnchor = item;
    const element = this.$refs[item.anchor] as HTMLFormElement;
    const top = element.getBoundingClientRect().top;
    const current = window.pageYOffset || document.documentElement.scrollTop;
    const header = this.$screenDetect.isMobile ? 135 : 198;
    window.scrollTo(0, current + top - header);
  }

  getNavList() {
    this.$nextTick(() => {
      const nav = this.$refs.nav as HTMLFormElement;
      const items = this.$refs['nav-item'] as HTMLFormElement;
      const logoWidth = 85 + 20;
      const moreWidth = 60;
      const max = nav.getBoundingClientRect().width - 40;
      const maxWihLogo = max - logoWidth - 20;
      let sum = 0;
      this.nav = [];
      this.navMore = [];
      this.navWihtLogo = [];
      this.navMoreWithLogo = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i].getBoundingClientRect();
        const config = this.navConfig[i];
        const element = this.$refs[config.anchor];
        if (!element) {
          continue;
        }
        sum += item.width;
        if (sum < max - moreWidth) {
          this.nav.push(config);
        } else {
          this.navMore.push(config);
        }
        if (sum < maxWihLogo - moreWidth) {
          this.navWihtLogo.push(config);
        } else {
          this.navMoreWithLogo.push(config);
        }
      }
      this.navList = [...this.nav];
      this.moreNavList = [...this.navMore];
    });
  }

  toggleMoreList(ev: MouseEvent) {
    ev.stopPropagation();
    this.dropdownOpen = !this.dropdownOpen;
  }

  handleScroll() {
    if (this.downloadAssetLoading.isLoading === undefined || this.downloadAssetLoading.isLoading) {
      return;
    }
    const current = window.pageYOffset || document.documentElement.scrollTop;
    let isScrollTop = false;
    if (current < this.lastScrollTop) {
      isScrollTop = true;
    }
    this.lastScrollTop = current <= 0 ? 0 : current;
    this.$nextTick(() => {
      const nav = this.$refs.nav as HTMLFormElement;
      if (nav.getBoundingClientRect().top === 0 || current - nav.offsetTop === 85) {
        this.showLogo = true;
        this.navList = [...this.navWihtLogo];
        this.moreNavList = [...this.navMoreWithLogo];
      } else {
        this.showLogo = false;
        this.navList = [...this.nav];
        this.moreNavList = [...this.navMore];
      }
      const header = (this.$screenDetect.isMobile ? 85 : 135) + 70;
      for (const index of this.navConfig) {
        const item = this.$refs[index.anchor] as HTMLFormElement;
        if (item && window.scrollY > current + item.getBoundingClientRect().top - header) {
          this.activeAnchor = index;
        }
      }
      if (isScrollTop || !this.$screenDetect.isMobile) {
        nav.style.top = '85px';
      } else {
        nav.style.top = '0px';
      }
    });
  }

  getShortLang(lang: string) {
    return lang && languages[lang.toLowerCase()];
  }
}
