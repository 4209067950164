
import PortraitLock from '@/components/ui/portrait-lock.vue';
import { ScreenType } from '@/plugins/screen-detect';
import AuthService from '@/services/auth';
import GameIconService from '@/services/game-icon';
import PortraitLockService from '@/services/portrait-lock';
import ScrollService from '@/services/scroll';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    PortraitLock,
  },
  subscriptions() {
    return {
      scrollToTop: ScrollService.scrollToTop$.pipe(
        tap((x) => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        })
      ),
    };
  },
})
export default class App extends Vue {
  private isLandscape = false;

  protected mounted() {
    AuthService.checkAuthentication();
    GameIconService.getImageBuilderUrl();
    this.$screenDetect.resize$
      .pipe(
        filter((detect: ScreenType) => !!detect.isMobile),
        map((_) => PortraitLockService.isLandscape()),
        distinctUntilChanged()
      )
      .subscribe((isLandscape: boolean) => {
        this.isLandscape = isLandscape;
      });
  }
}
