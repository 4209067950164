interface StringMap {
  [key: string]: string;
}
export const gameBackground: StringMap = {
  Microgaming:
    'https://mgasia.mitagtenni.net/direct/image/he1ujhqgnt6d3d55jirh380d7f/DPigX3fLckFW2cXgM7nnBMg_EWw/original?content-type=image%2Fpng&name=MGA_GameDetails_Background_Default_MG.png',
  UPG:
    'https://mgasia.mitagtenni.net/direct/image/ppvupdqts15fn6h1g3tfuncj36/_zff3UW5H49HYJdKUvfInYcbaRA/original?content-type=image%2Fpng&name=MGA_GameDetails_Background_Default_UPG.png',
  MGLive:
    'https://mgasia.mitagtenni.net/direct/image/fdadmn4uot1dre6gvnbofko259/O6ekHnSq6hbTrATRYKy3LSy2gcs/original?content-type=image%2Fpng&name=MGA_GameDetails_Background_Default_MGLive.png',
  WPQipai:
    'https://mgasia.mitagtenni.net/direct/image/p4caue72t94ch45cu6bubnpp6n/_8luU4BM-F0FxVDxquEB3jtQFzI/original?content-type=image%2Fpng&name=MGA_GameDetails_Background_Default_WP.png',
  WDFishing:
    'https://mgasia.mitagtenni.net/direct/image/8fqhpf1akt2a5c18s8n7k2et35/ye9vbyjCPv62YWNR1SwTaWNUir8/original?content-type=image%2Fpng&name=MGA_GameDetails_Background_Default_WD.png',
};
