import { Category as CategoryData } from '@/interfaces/http-response/get-categories';
import Category, { SubCategory } from '../category';

export class CategoryAdapter {
  // TODO: for now only can hard code by array index
  static readonly BranByIndex = ['TopGames', 'AllGames'];

  static adapt(data: CategoryData, index: number): Category {
    const brand = data.brand ? data.brand : this.BranByIndex[index];
    // remove default category once other categories exist.
    const categories = data.categories.length > 1 ? data.categories.slice(1) : data.categories;
    return new Category(brand, data.displayName, '', categories.map((category) => new SubCategory(category.displayName, category.games)));
  }
}
