import LoginModal from '@/components/common/modal/login.vue';
import { environment } from '@/configs/environment';
import { Locale } from '@/enums/lang';
import { StorageKey } from '@/enums/storage-key';
import { StorageType } from '@/enums/storage-type';
import { User } from '@/interfaces/user';
import AuthService from '@/services/auth';
import ModalService from '@/services/modal';
import StorageService from '@/services/storage';
import axios from 'axios';

const instance = axios.create({
  baseURL: environment.api,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use(
  (config) => {
    const user: any = StorageService.get(StorageKey.User, StorageType.Local);
    if (user && user.token) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      AuthService.logout();
      if (!ModalService.hasOpened) {
        ModalService.open(LoginModal, { overlayCanceling: false });
      }
    }
    return Promise.reject(error);
  }
);

export const Login = (user: User) => instance.post('/accounts/login', { internal: user });
export const GetCategories = <T>(locale: string = Locale.EN) => instance.get<T>(`/categories?locale=${locale}`);
export const GetGames = <T>(locale: string = Locale.EN) => instance.get<T>(`/games?locale=${locale}`);
export const GetSpecificGame = <T>(locale: string = Locale.EN, gameId: string) => instance.get<T>(`/games/${gameId}?locale=${locale}`);
export const GetBanners = <T>(type: string, locale: string = Locale.EN) => instance.get<T>(`/banners/${type}?locale=${locale}`);
export const LaunchGame = <T>(gameId: string, locale = Locale.EN, device: string, isDemoPlay: boolean) =>
  instance.post<T>(`/urls/launch`, {
    gameId,
    locale,
    device,
    isDemoPlay,
  });
export const GetNews = <T>(locale: string) => instance.get<T>(`/news?locale=${locale}`);
export const GetNewsDetail = <T>(id: string) => instance.get<T>(`/news/${id}`);
export const GetPromotions = <T>(locale: string) => instance.get<T>(`/promotions?locale=${locale}`);
export const GetPromotionsDetail = <T>(id: string) => instance.get<T>(`/promotions/${id}`);
export const SentMessage = (data: any) => instance.post('messages', data);
export const GetRecommendGames = <T>(gameId: string, locale: string = Locale.EN) =>
  instance.get<T>(`/games/${gameId}/recommendation?locale=${locale}`);
export const GetDownloadAssets = <T>(gameId: string, tag?: string) => {
  const url = tag ? `/games/${gameId}/assets/${tag}` : `/games/${gameId}/assets`;
  return instance.get<T>(url);
};
export const GetAdvertisements = <T>(locale: string) => instance.get<T>(`ads?locale=${locale}`);

export const GetTicker = <T>(currency: string, moduleid: number) =>
  axios
    .create({
      baseURL: 'https://api2.gameassists.co.uk/casino/progressive/public/v1/',
      headers: { 'Content-Type': 'application/json' },
    })
    .get<T>(`counters?currencyIsoCode=${currency}&moduleid=${moduleid}`);

export const GetAllTagsWithLocale = <T>(locale: string) => instance.get<T>(`/tags?locale=${locale}`);
export const GetAllGamesByTag = <T>(tagName: string, locale: string) => instance.get<T>(`/tags/${tagName}/games/?locale=${locale}`);
export const GetImageUrl = <T>() => instance.get<T>(`/urls/imageTemplate`);
