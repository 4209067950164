import { BehaviorSubject } from 'rxjs';
import _Vue from 'vue';

enum ScreenBreakPoint {
  DesktopHD = 1200,
  Desktop = 992,
  Tablet = 768,
  Mobile = 320,
}

export interface ScreenType {
  isDesktopHD: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

export class ScreenDetect {
  resize$ = new BehaviorSubject(this.detect);

  get detect() {
    return {
      isDesktopHD: this.isDesktopHD,
      isDesktop: this.isDesktop,
      isTablet: this.isTablet,
      isMobile: this.isMobile,
    };
  }

  get isDesktopHD() {
    return window.innerWidth >= ScreenBreakPoint.DesktopHD;
  }

  get isDesktop() {
    return window.innerWidth < ScreenBreakPoint.DesktopHD && window.innerWidth >= ScreenBreakPoint.Desktop;
  }

  get isTablet() {
    return window.innerWidth < ScreenBreakPoint.Desktop && window.innerWidth >= ScreenBreakPoint.Tablet;
  }

  get isMobile() {
    return window.innerWidth < ScreenBreakPoint.Tablet && window.innerWidth >= ScreenBreakPoint.Mobile;
  }
}

export default function ScreenDetectPlugin(Vue: typeof _Vue): void {
  const screenDetect = new ScreenDetect();

  window.addEventListener('resize', () => {
    screenDetect.resize$.next(screenDetect.detect);
  });
  window.addEventListener('orientationchange', () => {
    screenDetect.resize$.next(screenDetect.detect);
  });
  Vue.prototype.$screenDetect = screenDetect;
}
