import { Subject } from 'rxjs';
import { SweetModal } from 'sweet-modal-vue';
import Vue from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';

// tslint:disable-next-line:max-line-length
type ComponentInstance = CombinedVueInstance<Record<never, any> & Vue, object, object, object, Record<never, any>>;

class ModalRef {
  private closedSubject = new Subject();
  constructor(private instance: ComponentInstance) {}

  get closed$() {
    return this.closedSubject.asObservable();
  }
  private get modal() {
    return this.instance.$refs.modal as SweetModal;
  }

  close() {
    this.modal.close();
    this.closedSubject.next();
  }
}

export default ModalRef;
