
import FormButton from '@/components/ui/form-button.vue';
import { Locale } from '@/enums/lang';

import Game from '@/models/game';
import GameIconService from '@/services/game-icon';
import { Subscription, combineLatest } from 'rxjs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CategoryService from '@/services/category';
import GameService from '@/services/game';
import { startWith } from 'rxjs/operators';

@Component({
  props: {
    disableHover: Boolean,
    releaseDate: Boolean,
  },
  components: { FormButton },
})
export default class GameComponent extends Vue {
  subscriptions: Subscription = new Subscription();
  gameIconMap = new Map([]);
  errIconMap = new Set<Locale>();
  isLiveGames = false;
  liveSet = new Set(['Live', '真人', '라이브 게임']);
  imgBuilderUrl = '';
  @Prop({ default: () => new Game() }) game!: Game;
  @Prop({ default: () => false }) isGameDetail!: boolean;
  @Prop({ default: 'Game.Icon.PlayNow' }) buttonText!: string;
  @Prop({ default: () => false }) radius!: boolean;

  mounted() {
    const imgSubject = GameIconService.imageBuilderUrl$.subscribe((url) => {
      this.imgBuilderUrl = url;
    });

    this.subscriptions.add(imgSubject);
    const $game = this.$watchAsObservable('game').pipe(startWith(''));
    const liveGame = combineLatest([CategoryService.categories$, GameService.games$, $game]).subscribe(async ([categories, games]) => {
      const findGame = games.find((game) => game.gameCode === this.game.gameCode);
      if (findGame && categories.length !== 0) {
        categories.map((category) => {
          if (category.brand === "Microgaming") {
            const liveGames = category.categories.filter((subCategory) => this.liveSet.has(subCategory.name));
            if (liveGames && liveGames.length > 0) {
              this.isLiveGames = liveGames[0].games.some((game) => game === findGame?.gameId);
            }
          }
        });
      }
    });

    this.subscriptions.add(liveGame);

  }

  protected destroyed() {
    this.subscriptions.unsubscribe();
  }

  private get locale() {
    return this.$route.params.lang as Locale;
  }

  private get device() {
    return this.$screenDetect.isMobile ? 'mobile' : 'desktop';
  }

  @Watch('game.gameCode')
  gameCodeOnChange(val: string, to: string) {
    if (val !== to) {
      this.errIconMap = new Set<Locale>();
    }
  }

  gameIconPath(url: string, gameCode: string) {
    const defaultIcon = '/static/images/game/error-fetching.png';
    if (!url || !gameCode) {
      return defaultIcon;
    }
    const gameIconMap = new Map([
      [Locale.EN, `${url}/en/square/200x200/${gameCode}.jpg`],
      [Locale.CN, `${url}/zh_cn/square/200x200/${gameCode}.jpg`],
      [Locale.KO, `${url}/en/square/200x200/${gameCode}.jpg`],
    ]);
    const iconLang = this.errIconMap.size === 0 ? (this.$i18n.locale as Locale) : this.errIconMap.has(Locale.EN) ? Locale.CN : Locale.EN;

    const res = this.errIconMap.size === 3 ? defaultIcon : (gameIconMap.get(iconLang) as string);
    return res;
  }

  replaceByDefault(e: Event) {
    const ele = e.target as HTMLImageElement;
    const currentSrc = ele.src;
    if (currentSrc.indexOf('/en/') !== -1) {
      this.errIconMap.add(Locale.EN);
      this.errIconMap.add(Locale.KO);
    }
    if (currentSrc.indexOf('/zh_cn/') !== -1) {
      this.errIconMap.add(Locale.CN);
    }
    ele.src = this.gameIconPath(this.imgBuilderUrl, this.game.gameCode);
  }
}
