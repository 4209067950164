export default class Pagination {
  currentPage = 1;
  totalRecords = 0;
  perPage = 20;
  count = 5;

  constructor(options: { totalRecoreds: number; perPage?: number } = { totalRecoreds: 0, perPage: 10 }) {
    this.perPage = options.perPage || 20;
    this.totalRecords = options.totalRecoreds || 0;
  }

  get totalPage() {
    return Math.ceil(this.totalRecords / this.perPage) || 1;
  }

  ableToChangePageIndex(target: number) {
    return target >= 1 && target <= this.totalPage;
  }

  getItemList(): Array<number> {
    const range = this.updatePageIndexRange();
    let from = 0;
    if (this.currentPage < range.from) {
      from = 1;
    } else if (this.currentPage > range.to) {
      const targetPage = range.to - Math.floor(this.count / 2);
      from = targetPage > 0 ? targetPage : 1;
    } else {
      const targetPage = this.currentPage - Math.floor(this.count / 2);
      from = targetPage > 0 ? targetPage : 1;
    }
    const to = from + this.count - 1 >= this.totalPage ? this.totalPage : from + this.count - 1;
    const items: Array<number> = [];
    for (let i = from; i <= to; i++) {
      items.push(i);
    }
    return items;
  }

  range() {
    return {
      from: this.perPage * (this.currentPage - 1),
      to: this.perPage * this.currentPage,
    };
  }

  private updatePageIndexRange() {
    return {
      from: Math.ceil(this.count / 2),
      to: this.totalPage - Math.floor(this.count / 2),
    };
  }
}
