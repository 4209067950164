
import { debounceTime, filter, map, pluck } from 'rxjs/operators';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  subscriptions() {
    return {
      inputValue: this.$fromDOMEvent('input', 'input').pipe(
        pluck('target', 'value'),
        map((x) => x as string),
        debounceTime(300)
      ),
    };
  },
})
export default class FormSearch extends Vue {
  @Prop() title!: string;
  text = '';

  private mounted() {
    this.$observables.inputValue.subscribe((text) => {
      this.$emit('inputValue', text);
    });
  }

  private search(text: string = this.text) {
    this.$emit('search', text);
  }

  clear() {
    this.text = '';
    this.$emit('inputValue', '');
  }
  setText(text: string) {
    this.text = text;
  }
}
