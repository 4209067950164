import { GameHttpResponse } from '@/interfaces/http-response/get-games';
import { SpecificGameHttpResponse } from '@/interfaces/http-response/get-specific-game';
import Game from '@/models/game';
import { GameAdapter } from '@/models/http-response-adapter/get-games';
import { SpecificGameAdapter } from '@/models/http-response-adapter/get-specific-game';
import SpecificGame from '@/models/specific-game';
import { GetAllGamesByTag, GetGames, GetSpecificGame } from '@/services/api';
import GameState from '@/states/game';
import { BehaviorSubject } from 'rxjs';
const gameState = GameState.get();

class GameService {
  private games = new BehaviorSubject(gameState.games);
  private gamesError = new BehaviorSubject(false);
  private loadedGames: Array<{ lang: string; games: Game[] }> = [];
  private gamesByTag = new BehaviorSubject<Game[]>([]);

  get gamesByTag$() {
    return this.gamesByTag;
  }

  get games$() {
    return this.games;
  }

  get gamesError$() {
    return this.gamesError;
  }

  async getGames(lang: string) {
    if (this.loadedGames.find((x) => x.lang === lang)) {
      this.games.next(this.loadedGames.find((x) => x.lang === lang)!.games);
      return;
    }
    try {
      const gamesResponse = await GetGames<GameHttpResponse>(lang);
      const games = gamesResponse.data.map((x) => GameAdapter.adapt(x));
      this.loadedGames.push({ lang, games });
      this.games.next(games);
    } catch (error) {
      this.gamesError.next(true);
    }
  }

  async getSpecificGame(lang: string, gameId: string) {
    const response = await GetSpecificGame<SpecificGameHttpResponse>(lang, gameId);
    return SpecificGameAdapter.adapt(response.data);
  }

  async getAllGamesByTag(tagName: string, locale: string) {
    try {
      const response = await GetAllGamesByTag<GameHttpResponse>(tagName, locale);
      const games = response.data.map((x) => GameAdapter.adapt(x));
      this.gamesByTag.next(games);
    } catch (error) {
      this.gamesByTag.next([]);
    }
  }
}

const service = new GameService();

export default service;
