import { DownloadOption, ScreenShot, SpecificGameHttpResponse } from '@/interfaces/http-response/get-specific-game';
import Game from '@/models/game';

export default class SpecificGame implements SpecificGameHttpResponse {
  gameCode: string;
  brand!: string;
  brandDisplayName: string;
  gameType!: string;
  themes!: Array<string>;
  languages!: Array<{ launchLangCode: string; description: string }>;
  volatility!: string;
  theoreticalRTP!: string;
  gameFeatures: Array<string>;
  releaseDate: string;
  image!: {
    imagePath: string;
    imageId: string;
  };
  name!: string;
  description!: string;
  highlightedFeatures!: Array<string>;
  game!: Game;
  downloadOptions!: DownloadOption[];
  screenshots!: ScreenShot[];
  portrait!: string;
  gameFeatureLabels: Array<string>;
  background: string;
  gameMechanics!: {
    betLimits: string;
    maxExposure: string;
    lines: number;
    ways: string;
    reels: number;
    gameSizeInMb: string;
    isDemoPlay: boolean;
    canLaunch: boolean;
  };
  ldBetLimits!: {
    player: string;
    table: string;
    triple: string;
  };

  constructor(
    data: SpecificGameHttpResponse = {
      gameCode: '',
      brand: '',
      brandDisplayName: '',
      gameType: '',
      themes: [],
      languages: [],
      volatility: '',
      theoreticalRTP: '',
      gameFeatures: [],
      releaseDate: '',
      image: {
        imagePath: '',
        imageId: '',
      },
      name: '',
      description: '',
      highlightedFeatures: [],
      downloadOptions: [],
      screenshots: [],
      portrait: '',
      gameMechanics: {
        betLimits: '',
        maxExposure: '',
        lines: 0,
        ways: '',
        reels: 0,
        gameSizeInMb: '',
        isDemoPlay: false,
        canLaunch: false,
      },
      ldBetLimits: {
        player: '',
        table: '',
        triple: '',
      },
      gameFeatureLabels: [],
      background: '',
    }
  ) {
    this.game =
      new Game({
        brand: data.brand,
        gameId: data.gameCode,
        gameCode: data.gameCode,
        name: data.name,
        gameFeatures: data.gameFeatures,
        highlightedFeatures: data.highlightedFeatures,
        releaseDate: data.releaseDate,
      }) || new Game();
    this.gameCode = data.gameCode || '';
    this.brand = data.brand || '';
    (this.brandDisplayName = data.brandDisplayName || ''), (this.gameType = data.gameType || '');
    this.themes = data.themes || [];
    this.languages = data.languages || [];
    this.volatility = data.volatility || '';
    this.theoreticalRTP = data.theoreticalRTP || '';
    this.gameFeatures = data.gameFeatures || [];
    this.releaseDate = data.releaseDate || '';
    this.description = data.description || '';
    this.highlightedFeatures = data.highlightedFeatures || [];
    this.downloadOptions = data.downloadOptions || [];
    this.screenshots = data.screenshots || [];
    this.portrait = data.portrait || '';
    this.gameMechanics = {
      betLimits: data.gameMechanics.betLimits || '',
      maxExposure: data.gameMechanics.maxExposure || '',
      lines: data.gameMechanics.lines || 0,
      ways: data.gameMechanics.ways || '',
      reels: data.gameMechanics.reels || 0,
      gameSizeInMb: data.gameMechanics.gameSizeInMb || '',
      isDemoPlay: data.gameMechanics.isDemoPlay || false,
      canLaunch: data.gameMechanics.canLaunch || false,
    };
    this.ldBetLimits = {
      player: data.ldBetLimits.player || '',
      table: data.ldBetLimits.table || '',
      triple: data.ldBetLimits.triple || '',
    };
    this.gameFeatureLabels = data.gameFeatureLabels || [];
    this.background = data.background || '';
  }

  isLiveNow() {
    return this.game.isLiveNow();
  }

  getReleaseDateString(format: string) {
    return this.game.getReleaseDateString(format);
  }
}
