import dayjs from 'dayjs';
import NewsDetail, { Content } from '../news-detail';

export class NewsDetailAdapter {
  static adapt({
    tags,
    publishDate,
    locale,
    title,
    summary,
    thumbnail,
    content,
  }: {
    tags: string[];
    publishDate: string;
    locale: string;
    title: string;
    summary: string;
    thumbnail: string;
    content: Array<{
      image: {
        imageId: string;
        imagePath: string;
      };
      paragraph: string;
      blockType: number;
    }>;
  }): NewsDetail {
    const SUMMARY_MAX_LENGTH = 70;
    if (summary.length > SUMMARY_MAX_LENGTH) {
      summary = `${summary.substr(0, SUMMARY_MAX_LENGTH)}...`;
    }
    return new NewsDetail({
      tags,
      publishDate: dayjs(publishDate).format('YYYY/MM/DD'),
      title,
      locale,
      summary,
      content: content.map((x) => new Content(x.image, x.paragraph, x.blockType)),
    });
  }
}
