
import FilterBlocks from '@/components/ui/filter-blocks.vue';
import SortBy from '@/components/ui/form-sortby.vue';
import NoData from '@/components/ui/no-data.vue';
import Slider from '@/components/ui/slider.vue';
import Tabs from '@/components/ui/tabs.vue';
import { BannerType } from '@/enums/banner-type';
import { NewsType } from '@/enums/news-type';
import { SortType } from '@/enums/sort-type';
import Banner from '@/models/banner';
import NewsModel from '@/models/news';
import { StateModel } from '@/models/state-model';
import NewsCard from '@/pages/news/news-card.vue';
import BannerService from '@/services/banner';
import NewsServices from '@/services/news';
import ScrollService from '@/services/scroll';
import { take } from 'rxjs/operators';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

const CONTENT_MAX_LENGTH = 70;
const COUNT_PER_PAGE = 9;
@Component({
  components: {
    SortBy,
    Slider,
    Tabs,
    NewsCard,
    NoData,
    FilterBlocks,
  },
})
export default class News extends Vue {
  @Ref('sortBy') sortByComponent!: SortBy;
  @Ref('blocks') blocksComponent!: FilterBlocks;
  bannerList: Array<Banner> = [];
  newsList: Array<NewsModel> = [];
  currentPage = 0;
  stateModel = new StateModel();
  labels = [
    { text: 'News.Tabs.All', value: NewsType.All },
    { text: 'News.Tabs.Game', value: NewsType.Game },
    { text: 'News.Tabs.Promotions', value: NewsType.Promotion },
  ];
  defaultIndex = 0;
  showTabs = false;
  private news: Array<NewsModel> = [];
  private currentLabel: { text: string; value: NewsType } = this.labels[0];

  scroll(ev: Event) {
    const isMounted = this.stateModel.isMounted;
    if (isMounted && window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.stateModel.loading) {
      this.loadNewsByPage();
    }
  }

  async initialize(options: { newsType: NewsType; reload: boolean } = { reload: false, newsType: NewsType.All }) {
    this.currentPage = 0;
    this.newsList = [];
    this.news = [];
    this.news = await this.getNews({
      sortType: SortType.Default,
      reload: true,
    });
    if (options.newsType) {
      this.currentLabel = this.labels.find((x) => x.value === options.newsType)!;
      this.news = this.filteredByNewsType(this.news);
    }
    this.showTabs = !!this.news.length;
    this.loadNewsByPage();
    const bannerList = await BannerService.getBanners(this.$route.params.lang, BannerType.News);
    this.bannerList = bannerList.map((x) => ({ ...x, imageMobile: x.imageDesktop }));
  }

  private loadNewsByPage() {
    const isLoad = this.currentPage <= Math.ceil(this.newsList.length / COUNT_PER_PAGE);
    const isNewsReady = this.news.length > 0;
    if (isLoad && isNewsReady) {
      this.stateModel.loading = true;
      this.stateModel.isMounted = true;
      const moreNews = this.news.slice(this.currentPage * COUNT_PER_PAGE, (this.currentPage + 1) * COUNT_PER_PAGE);
      this.newsList.push(...moreNews);
      this.currentPage++;
      setTimeout(() => {
        this.stateModel.loading = false;
      }, 700);
    }
  }

  @Watch('$route.params.lang', { immediate: true, deep: true })
  private async onUrlChange(newVal: Route) {
    ScrollService.scrollToTop();
    try {
      const tabIndex = +this.$route.query.tabIndex;
      let newsType = NewsType.All;
      if (tabIndex) {
        newsType = this.labels[tabIndex].value;
        this.defaultIndex = tabIndex;
      } else {
        this.defaultIndex = 0;
      }
      await this.initialize({
        reload: true,
        newsType,
      });
      if (this.sortByComponent) {
        this.sortByComponent.reset();
      }
      this.showTabs = NewsServices.isNewsExists;
    } catch (e) {
      this.$router.push(`/${this.$i18n.locale}/404`);
      console.error(e);
    }
  }

  async sortChange(item: string) {
    const sortOptions = [
      { text: this.$t('Components.FormSortby.Default'), value: SortType.Default },
      { text: this.$t('Components.FormSortby.NewestOnTop'), value: SortType.NewestOnTop },
      { text: this.$t('Components.FormSortby.OldestOnTop'), value: SortType.OldestOnTop },
    ];
    const sortType = sortOptions.filter((o) => o.text === item)[0].value;
    const news = await this.getNews({ sortType });
    this.news = this.filteredByNewsType(news);
    this.newsList = [];
    this.currentPage = 0;
    this.loadNewsByPage();
  }

  async getNews(options: { sortType: SortType; reload?: boolean } = { sortType: SortType.Default, reload: false }) {
    return await NewsServices.getNews(this.$route.params.lang, options.sortType, options.reload || false);
  }

  async tabSwitch(ev: { text: string; value: NewsType }) {
    this.currentLabel = ev;
    const tabIndex = this.labels.findIndex((x) => x.value === this.currentLabel.value).toString();
    this.$router.replace({ name: 'news-list', query: { tabIndex } }).catch((err) => {});
    const news = await this.getNews();
    this.news = this.filteredByNewsType(news);
    this.newsList = [];
    this.currentPage = 0;
    this.sortByComponent.reset();
    this.loadNewsByPage();
  }

  private filteredByNewsType(news: Array<NewsModel>) {
    const type = this.currentLabel.value;
    const newsTypeTag = this.$t(this.labels.filter((o) => o.value === type)[0].text) as string;
    return news.filter((o) =>
      type === NewsType.All ? true : o.tags.map((tag: string) => tag.toLowerCase()).includes(newsTypeTag.toLowerCase())
    );
  }

  protected destroyed() {
    NewsServices.clearSource();
  }
}
