
import ModalService from '@/services/modal';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import NotificationDetailModal, { NotificationDetailModalData } from '../common/modal/notification-detail.vue';

@Component({
  components: {
    NotificationDetailModal,
  },
})
export default class Notification extends Vue {
  @Prop() private isDrawer!: boolean;
  private isShowDropdown = false;
  private notificationList: any[] = [
    {
      title:
        '11111188MG has launched a spooky new slot, The Wolf’s Bane, where players take on the perspective of a fanged, clawed menace.',
      detail:
        'Released in time for Halloween, The Wolf’s Bane is set in a ravaged 18th century village. The 5-reel, 3-row, 10-payline video slot sees contains wild substitutions, free spins and a bonus pick-and-click feature.',
      date: '2019/10/14, 14:15',
    },
    {
      title:
        '222222288MG has launched a spooky new slot, The Wolf’s Bane, where players take on the perspective of a fanged, clawed menace.',
      detail:
        'Released in time for Halloween, The Wolf’s Bane is set in a ravaged 18th century village. The 5-reel, 3-row, 10-payline video slot sees contains wild substitutions, free spins and a bonus pick-and-click feature.',
      date: '2019/10/14, 14:15',
    },
    {
      title:
        '33333388MG has launched a spooky new slot, The Wolf’s Bane, where players take on the perspective of a fanged, clawed menace.',
      detail:
        'Released in time for Halloween, The Wolf’s Bane is set in a ravaged 18th century village. The 5-reel, 3-row, 10-payline video slot sees contains wild substitutions, free spins and a bonus pick-and-click feature.',
      date: '2019/10/14, 14:15',
    },
    {
      title:
        '44444488MG has launched a spooky new slot, The Wolf’s Bane, where players take on the perspective of a fanged, clawed menace.',
      detail:
        'Released in time for Halloween, The Wolf’s Bane is set in a ravaged 18th century village. The 5-reel, 3-row, 10-payline video slot sees contains wild substitutions, free spins and a bonus pick-and-click feature.',
      date: '2019/10/14, 14:15',
    },
    {
      title:
        '555555588MG has launched a spooky new slot, The Wolf’s Bane, where players take on the perspective of a fanged, clawed menace.',
      detail:
        'Released in time for Halloween, The Wolf’s Bane is set in a ravaged 18th century village. The 5-reel, 3-row, 10-payline video slot sees contains wild substitutions, free spins and a bonus pick-and-click feature.',
      date: '2019/10/14, 14:15',
    },
  ];

  private handleDropdown() {
    const width = window.innerWidth;
    const pcWidth = 768;
    if (width >= pcWidth) {
      this.isShowDropdown = !this.isShowDropdown;
    } else {
      return;
    }
  }

  private openModal() {
    const ref = ModalService.open<NotificationDetailModalData>(NotificationDetailModal, {
      data: {
        notificationList: this.notificationList,
      },
    });
  }

  private logout() {
    this.$emit('logout');
  }

  @Emit()
  private switchClick(event: boolean) {
    return event;
  }
}
