import '@/assets/styles/main.scss';
import { ScrollDirective } from '@/directives/scroll';
import { TranslationHtmlDirective } from '@/directives/translation';
import VueScreenDetect from '@/plugins/screen-detect';
import ModalService from '@/services/modal';
import { i18n } from '@/setup/i18n-setup';
import { Button, Carousel, CarouselItem, Drawer, Dropdown, DropdownItem, DropdownMenu, Switch } from 'view-design';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import VueRx from 'vue-rx';
import Vuelidate from 'vuelidate';
import '../my-theme/index.less';
import App from './app.vue';
import router from './router';
import './setup/vue-gtm-setup';

Vue.component('Button', Button);
Vue.component('i-switch', Switch);
Vue.component('Drawer', Drawer);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Carousel', Carousel);
Vue.component('CarouselItem', CarouselItem);
Vue.directive('scroll', ScrollDirective);
Vue.directive('translation-html', TranslationHtmlDirective);

Component.registerHooks(['validations']);

Vue.config.productionTip = false;
Vue.use(VueRx);
Vue.use(Vuelidate);
Vue.use(VueScreenDetect);

Vue.filter('uppercase', (value: any) => value.toString().toUpperCase());



const options = {
  i18n,
  router,
};

const vm = new Vue({
  ...options,
  render: (h) => h(App),
}).$mount('#app');

ModalService.setContainer(vm.$root.$el, options);
