
import Pagination from '@/models/pagination';
import { ScreenType } from '@/plugins/screen-detect';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class PaginationComponent extends Vue {
  @Prop({ default: 0 }) total!: number;
  @Prop({ default: () => new Pagination() }) pagination!: Pagination;
  items: Array<number> = [];
  screenType: ScreenType = {} as ScreenType;

  mounted() {
    this.$screenDetect.resize$.subscribe((detect: ScreenType) => {
      this.screenType = detect;
      if (this.screenType.isMobile) {
        this.pagination.count = 3;
      } else {
        this.pagination.count = 5;
      }
      this.updatePageIndex();
    });
  }

  goTo(index: number) {
    this.action(index);
  }

  next() {
    const target = this.pagination.currentPage + 1;
    this.action(target);
  }

  previous() {
    const target = this.pagination.currentPage - 1;
    this.action(target);
  }

  action(target: number) {
    if (this.pagination.ableToChangePageIndex(target)) {
      this.pagination.currentPage = target;
      this.updatePageIndex();
    }
  }

  @Watch('pagination.totalRecords', { immediate: true })
  private onTotalRecordsChange(to: number, from: number) {
    this.updatePageIndex();
  }

  @Watch('pagination.currentPage', { immediate: true })
  private onCurrentPageChange(to: number, from: number) {
    this.updatePageIndex();
  }

  private updatePageIndex() {
    this.items = this.pagination.getItemList();
  }
}
