
import Category, { SubCategory } from '@/models/category';
import { SelectedCategory } from '@/models/selected-category';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class CategoryMenu extends Vue {
  @Prop({ default: 'desktop' })
  private readonly type!: 'desktop' | 'mobile';
  @Prop({ default: () => [] })
  private readonly categories!: Category[];
  @PropSync('selectedCategory', { type: SelectedCategory })
  private syncedSelectedCategory!: SelectedCategory;

  get isMobile() {
    return this.$props.type === 'mobile';
  }

  isBrandActive(categoryName: string) {
    return this.syncedSelectedCategory.selectedBrand === categoryName;
  }

  isBrandToggled(categoryName: string) {
    return this.syncedSelectedCategory.toggleMenu === categoryName;
  }

  isSubCategoryActive(category: Category, subCategory: SubCategory) {
    return this.syncedSelectedCategory.uniqueKey === `${category.brand}-${subCategory.name}`;
  }

  hasChildren(category: Category): boolean {
    return !(category.name === category.categories[0].name && category.categories.length === 1);
  }

  setCategory(category: any, subCategory: any) {
    if (!subCategory) {
      if (!this.hasChildren(category)) {
        subCategory = category.categories[0];
      } else {
        return;
      }
    }

    this.$emit('categorychange');
    if (
      category.brand === this.syncedSelectedCategory.categoryBrand.brand &&
      subCategory.name === this.syncedSelectedCategory.category.name
    ) {
      return;
    }
    this.$router.replace(`/${this.$i18n.locale}/games/${category.brand}/${subCategory.name}`);
  }

  isShowCategoryIcon(category: Category) {
    return category.name === 'MG Live Grand' || category.name === 'MG真人豪华厅';
  }
}
