
import { Component, Vue } from 'vue-property-decorator';

export interface IframeModalData {
  url: string;
}

@Component
export default class IframeModal extends Vue {
  data: IframeModalData = {
    url: '',
  };
}
