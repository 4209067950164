import { SortType } from '@/enums/sort-type';
import { NewsHttpResponse } from '@/interfaces/http-response/get-news';
import { NewsDetailHttpResponse } from '@/interfaces/http-response/get-news-detail';
import { NewsAdapter } from '@/models/http-response-adapter/get-news';
import { NewsDetailAdapter } from '@/models/http-response-adapter/get-news-detail';
import NewsModel from '@/models/news';
import { GetNews, GetNewsDetail } from './api';

import dayjs from 'dayjs';

class NewsService {
  newsRawSource!: NewsHttpResponse | null;

  get isNewsExists(): boolean {
    return this.newsRawSource ? !!this.getNewsFromResponse(this.newsRawSource).length : false;
  }

  async getNews(lang: string, sortType: SortType = SortType.Default, reload = false) {
    if (!this.newsRawSource || reload) {
      const newsResponse = await GetNews<NewsHttpResponse>(lang);
      this.newsRawSource = newsResponse.data;
      const news = this.getNewsFromResponse(newsResponse.data);
      return this.sortingArticle(news, sortType);
    } else {
      const news = this.getNewsFromResponse(this.newsRawSource);
      return this.sortingArticle(news, sortType);
    }
  }

  async getNewsDetail(id: string) {
    const newsDetailResponse = await GetNewsDetail<NewsDetailHttpResponse>(id);
    return NewsDetailAdapter.adapt(newsDetailResponse.data);
  }

  clearSource() {
    this.newsRawSource = null;
  }

  private getNewsFromResponse(response: NewsHttpResponse): Array<NewsModel> {
    return this.getVisible(response.map((x) => NewsAdapter.adapt(x)));
  }

  private getVisible(news: Array<NewsModel>): Array<NewsModel> {
    return news.filter((o) => dayjs(o.publishDate).isBefore(dayjs()));
  }

  private sortingArticle(articles: Array<NewsModel>, sortType: SortType = SortType.Default) {
    const sortedArticles = articles.sort((a, b) => {
      return dayjs(a.publishDate).isAfter(b.publishDate) ? -1 : 1;
    });
    switch (sortType) {
      case SortType.NewestOnTop:
        return sortedArticles;
      case SortType.OldestOnTop:
        sortedArticles.sort((a, b) => {
          return dayjs(a.publishDate).isBefore(b.publishDate) ? -1 : 1;
        });
        return sortedArticles;
      default:
        return [...sortedArticles.filter((o) => o.highlighted), ...sortedArticles.filter((o) => !o.highlighted)];
    }
  }
}

const service = new NewsService();

export default service;
