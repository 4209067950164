import { GetDownloadAssetsHttpResponse } from '@/interfaces/http-response/get-download-assets';
import { GameHttpResponse } from '@/interfaces/http-response/get-games';
import { GameAdapter } from '@/models/http-response-adapter/get-games';
import { GetDownloadAssets, GetRecommendGames } from '@/services/api';

class GameDetailService {
    async getRecommendGames(gameId: string, lang: string) {
        const gamesResponse = await GetRecommendGames<GameHttpResponse>(gameId, lang);
        return gamesResponse.data.map((x) => GameAdapter.adapt(x));
    }

    async getDownloadAssets(gameId: string, tag?: string) {
        const gamesResponse = await GetDownloadAssets<GetDownloadAssetsHttpResponse>(gameId, tag);
        return gamesResponse.data;
    }
}

const service = new GameDetailService();

export default service;
