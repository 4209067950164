import { BannerType } from '@/enums/banner-type';
import { BannerHttpResponse } from '@/interfaces/http-response/get-banner';
import { BannerAdapter } from '@/models/http-response-adapter/get-banners';
import { GetBanners } from './api';

class BannerService {
  async getBanners(lang: string, type: BannerType) {
    const bannerResponse = await GetBanners<BannerHttpResponse>(type, lang);
    return bannerResponse.data.map((x) => BannerAdapter.adapt(x));
  }
}

const service = new BannerService();

export default service;
