class PortraitLockService {
  isLandscape() {
    const windowSizeCache = {
      width: window.screen.availWidth || document.documentElement.clientWidth,
      height: window.screen.availHeight || document.documentElement.clientHeight,
    };
    const isKeyboard =
      document.documentElement.clientWidth === windowSizeCache.width && document.documentElement.clientHeight !== windowSizeCache.height;
    const isLandscape =
      document.documentElement.clientWidth > document.documentElement.clientHeight || window.screen.width > window.screen.height;
    return !isKeyboard && isLandscape;
  }
}

const service = new PortraitLockService();

export default service;
