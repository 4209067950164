import { StorageType } from '@/enums/storage-type';

class StorageService {
  set(key: string, value: any, type: StorageType = StorageType.Session) {
    const data = {
      value,
    };
    this.getStorage(type).setItem(key, JSON.stringify(data));
  }

  get(key: string, type = StorageType.Session) {
    const data = JSON.parse(this.getStorage(type).getItem(key) || '{"value": ""}');
    return data.value;
  }

  clear(type = StorageType.Session) {
    this.getStorage(type).clear();
  }

  removeKey(key: string, type: StorageType = StorageType.Session) {
    this.getStorage(type).removeItem(key);
  }

  private getStorage(type: StorageType) {
    return type === StorageType.Session ? sessionStorage : localStorage;
  }
}

const service = new StorageService();

export default service;
