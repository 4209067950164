
import Banner from '@/models/banner.ts';
import { ScreenType } from '@/plugins/screen-detect';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  props: {
    mask: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Slider extends Vue {
  @Prop({ default: () => [] }) resources!: Banner[];
  @Prop({ default: 'inside' }) dotStyle!: 'none' | 'inside' | 'outside';
  @Prop() type!: string;
  imgList: any = [];
  value = 0;
  isMobile = false;
  get setting() {
    return this.resources.length > 1
      ? {
          autoplay: true,
          autoplaySpeed: 5000,
          radiusDot: true,
          trigger: 'click',
          arrow: 'never',
        }
      : {
          trigger: 'click',
          arrow: 'never',
        };
  }
  @Watch('resources')
  private onResourceChange(newVal: Banner[]) {
    this.value = 0;
    this.setup(this.$screenDetect.detect);
  }

  protected mounted() {
    this.$screenDetect.resize$.subscribe((detect: ScreenType) => {
      this.setup(detect);
    });
  }

  private getDotsType() {
    return this.imgList.length <= 1 ? 'none' : this.dotStyle;
  }

  private setup(detect: ScreenType) {
    this.isMobile = detect.isMobile;
    if (this.resources) {
      const resources = this.resources
        .filter((x) => (detect.isMobile ? x.imageMobile : x.imageDesktop))
        .map((slider) => {
          return {
            image: detect.isMobile && slider.imageMobile ? slider.imageMobile : slider.imageDesktop,
            link: slider.link,
          };
        });
      this.imgList = [...resources];
    }
  }

  private goToUrl(index: number) {
    const url = this.imgList[index].link;
    if (!url) {
      return;
    }
    window.open(/^http(s?)(.*)$/.test(url) ? url : `http://${url}`, '_blank');
  }
}
