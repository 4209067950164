
import News from '@/models/news';
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class NewsCard extends Vue {
  @Prop() news!: News;

  formatDate(date: string) {
    return dayjs(date).format(this.$t('DateFormat') as string);
  }
}
