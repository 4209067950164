import GeneralModal from '@/components/common/modal/general.vue';
import { Locale } from '@/enums/lang';
import { GameUrlHttpResponse } from '@/interfaces/http-response/get-game-url';
import { LaunchGame } from '@/services/api';
import ModalService from '@/services/modal';

import dayjs from 'dayjs';

interface GameOptions {
  brand?: string;
  gameId?: string;
  name?: string;
  highlightedFeatures?: string[];
  gameFeatures?: string[];
  releaseDate?: string;
  gameType?: string;
  gameCode?: string;
  volatility?: string;
}
export default class Game {
  brand: string;
  gameId: string;
  name: string;
  highlightedFeatures: string[];
  gameFeatures: string[];
  releaseDate: string;
  gameType: string;
  gameCode: string;
  volatility: string;
  subCategory?: string;

  constructor(options: GameOptions = {}) {
    this.brand = options.brand || '';
    this.gameId = options.gameId || '';
    this.name = options.name || '';
    this.highlightedFeatures = options.highlightedFeatures || [];
    this.gameFeatures = options.gameFeatures || [];
    this.releaseDate = options.releaseDate || '';
    this.gameType = options.gameType || '';
    this.gameCode = options.gameCode || '';
    this.volatility = options.volatility || '';
  }

  async launch(gameId: string, locale: Locale, device: string, isDemoPlay: boolean) {
    let response;
    const newTabTarget = this.shouldOpenSelf() ? '_self' : '_blank';
    const newTab = window.open('', newTabTarget)!;
    try {
      response = await LaunchGame<GameUrlHttpResponse>(gameId, locale, device, isDemoPlay);
    } catch (e) {
      newTab.close();
      const GAME_NOT_EXIST = '80007';
      if (e.response && e.response.data.Code === GAME_NOT_EXIST) {
        ModalService.open(GeneralModal, {
          data: { type: 'ComingSoon' },
          overlayCanceling: false,
        });
      } else {
        ModalService.open(GeneralModal, {
          data: { type: 'Error' },
          overlayCanceling: false,
        });
      }
    } finally {
      if (response) {
        newTab.location.href = response.data.url;
      }
    }
  }

  getReleaseDateString(format: string) {
    return dayjs(this.releaseDate).format(format);
  }

  isLiveNow() {
    return dayjs().isAfter(dayjs(this.releaseDate));
  }

  private shouldOpenSelf() {
    const useragent = navigator.userAgent;
    const rules = ['OppoBrowser', 'WebView', '(iPhone|iPod|iPad)(?!.*CriOS/)', 'Android.*(wv|.0.0.0)'];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');
    return Boolean(useragent.match(regex));
  }
}
