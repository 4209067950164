
import { SweetModal } from 'sweet-modal-vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    SweetModal,
  },
})
export default class Modal extends Vue {
  private _width = '';
  private _overlayTheme = 'dark';
  private _overlayCanceled = true;
  private _hideCloseButtonTopRight = false;

  setOverlayTheme(theme: 'light' | 'dark' = 'dark') {
    this._overlayTheme = theme;
  }

  setWidth(width?: string) {
    if (!width) {
      return;
    }
    this._width = width;
  }

  setOverlayCanceling(isCanceled = true) {
    this._overlayCanceled = isCanceled;
  }

  setHideCloseButton(isHidden = false) {
    this._hideCloseButtonTopRight = isHidden;
  }
}
