import dayjs from 'dayjs';

interface IPromotionDetail {
  publishDate?: string;
  startDate?: string;
  endDate?: string;
  locale?: string;
  title?: string;
  subtitle?: string;
  thumbnail?: { imageId: string; imagePath: string };
  content?: Content[];
}

export default class PromotionDetail {
  publishDate = '';
  startDate = '';
  endDate = '';
  locale = '';
  title = '';
  subtitle = '';
  thumbnail = {};
  content: Content[] = [];
  constructor(promotionDetail: IPromotionDetail = {}) {
    this.publishDate = promotionDetail.publishDate || '';
    this.startDate = promotionDetail.startDate || '';
    this.endDate = promotionDetail.endDate || '';
    this.locale = promotionDetail.locale || '';
    this.title = promotionDetail.title || '';
    this.subtitle = promotionDetail.subtitle || '';
    this.content = promotionDetail.content || [];
    this.thumbnail = promotionDetail.thumbnail || {};
  }

  getEventPeriod(format: string, separator = ' - ') {
    return [dayjs(this.startDate).format(format), dayjs(this.endDate).format(format)].join(separator);
  }
}

export class Content {
  constructor(
    public image: {
      imageId: string;
      imagePath: string;
    } = { imageId: '', imagePath: '' },
    public paragraph: string = '',
    public blockType: number = 0
  ) {}
}
