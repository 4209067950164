
import BlockContent from '@/components/ui/blocks-content.vue';
import ContentBanner from '@/components/ui/content-banner.vue';
import ContentDetail from '@/components/ui/content-detail.vue';
import NewsDetailModel from '@/models/news-detail';
import NewsServices from '@/services/news';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { EventAction, EventCategory, EventLabel } from '../../configs/gtm';

@Component({
  components: {
    ContentBanner,
    ContentDetail,
    BlockContent,
  },
})
export default class NewsDetail extends Vue {
  newsDetail: NewsDetailModel = new NewsDetailModel();

  @Watch('$route', { immediate: true, deep: true })
  private async onUrlChange(newVal: Route) {
    try {
      const newsDetail = await NewsServices.getNewsDetail(this.$route.params.id);
      if (newsDetail.locale.toLowerCase() === this.$route.params.lang.toLowerCase()) {
        this.newsDetail = newsDetail;
        this.newsArticlePageView(newsDetail.title);
      } else {
        throw Error('Localization is not match.');
      }
    } catch (e) {
      console.error(e);
      this.$router.push(`/${this.$route.params.lang}/news`);
    }
  }

  private newsArticlePageView(title: string) {
    this.$gtm.trackEvent({
      event: `idvNewsPageView`,
      category: EventCategory.NewsPage,
      action: EventAction.View,
      label: EventLabel.NewsArticlePageView(title),
    });
  }

  private backToList() {
    const tabIndex = this.$route.query.tabIndex;
    this.$router.push({
      path: `/${this.$route.params.lang}/news`,
      query: { tabIndex },
    });
  }
}
