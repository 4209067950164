
import GameComponent from '@/components/common/game/game.vue';
import Game from '@/models/game';
import { ScreenType } from '@/plugins/screen-detect';
import { tap } from 'rxjs/operators';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    GameComponent,
  },
})
export default class HomePromtion extends Vue {
  @Prop() gameList!: Game[];
  private isMobile = false;
  private currentIndex = 0;
  get count() {
    return this.gameList ? Math.ceil(this.gameList.length / 6) : 0;
  }

  mounted() {
    (this as any).$screenDetect.resize$.subscribe((type: ScreenType) => {
      this.isMobile = type.isMobile;
    });
  }

  gameListSlice(to: number) {
    const from = to - 1;
    const games = [...this.gameList.slice(from * 6, to * 6)];
    if (games.length < 6) {
      games.push(...new Array(6 - games.length));
    }
    return games;
  }

  shift(index: number) {
    return {
      transform: `translateX(${(index - 1 - this.currentIndex) * 100}%)`,
    };
  }

  previous() {
    this.currentIndex = this.currentIndex ? this.currentIndex - 1 : 0;
  }

  next() {
    this.currentIndex = this.currentIndex < this.count - 1 ? this.currentIndex + 1 : this.currentIndex;
  }

  viewDetailPage(gameId: string) {
    this.$router.push(`/${this.$i18n.locale}/game/${gameId}`);
  }
}
