import { StorageKey } from '@/enums/storage-key';
import { StorageType } from '@/enums/storage-type';
import { User } from '@/interfaces/user';
import { Login } from '@/services/api';
import StorageService from '@/services/storage';
import { BehaviorSubject, Observable } from 'rxjs';

class AuthService {
  private isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isAuthenticated(): boolean {
    return this.isAuthenticatedSubject.value;
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  async login(user: User) {
    const response = await Login(user);
    StorageService.set(
      StorageKey.User,
      {
        user: user.username,
        token: response.data.token,
      },
      StorageType.Local
    );
    this.isAuthenticatedSubject.next(true);
  }

  oktaLogin(token: any) {
    StorageService.set(
      StorageKey.User,
      {
        user: 'okta',
        token,
      },
      StorageType.Local
    );
    this.isAuthenticatedSubject.next(true);
  }

  logout() {
    StorageService.removeKey(StorageKey.User, StorageType.Local);
    this.isAuthenticatedSubject.next(false);
  }

  checkAuthentication() {
    const user = StorageService.get(StorageKey.User, StorageType.Local);
    if (user) {
      this.isAuthenticatedSubject.next(true);
    }
  }
}

const service = new AuthService();

export default service;
