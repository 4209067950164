
import FormButton from '@/components/ui/form-button.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    FormButton,
  },
})
export default class ContentDetail extends Vue {
  back() {
    this.$emit('back');
  }
}
