
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MGReward extends Vue {
  readonly anchorSectionOrderedList1 = ['tournament', 'epicwin', 'freespin', 'jackpot', 'cashback'];
  activeAnchorClass1 = '';

  readonly anchorSectionOrderedList2 = ['prestige', 'coins', 'playerzone', 'lobby', 'ingame'];
  activeAnchorClass2 = '';

  readonly sectionOrderedList = ['integration', 'free', 'zerohassle', 'growth'];

  get lang() {
    switch (this.$route.params.lang) {
      case 'zh-CN':
        return 'cn';
      default:
        return 'en';
    }
  }

  created() {
    window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  scrollTo(refName: string) {
    window.scrollTo({ top: this.getRefOffsetTop(refName), behavior: 'smooth' });
  }

  private getRefOffsetTop(refName: string) {
    const stickyAnchorSize = 54;
    const element = this.$refs[refName] as HTMLElement;
    return element.offsetTop - stickyAnchorSize;
  }

  handleScroll(event: any) {
    const top = document.documentElement.scrollTop || document.body.scrollTop;
    const section1 = [...this.anchorSectionOrderedList1].reverse();
    const currentSection1 = section1.find((refName) => {
      if (top >= this.getRefOffsetTop(refName) && top < this.getRefOffsetTop('hidden-tab')) {
        return refName;
      }
    });
    const section2 = [...this.anchorSectionOrderedList2].reverse();
    const currentSection2 = section2.find((refName) => {
      if (top >= this.getRefOffsetTop(refName)) {
        return refName;
      }
    });
    this.activeAnchorClass1 = currentSection1 || '';
    this.activeAnchorClass2 = currentSection2 || '';
  }
}
