import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface LoadingEntity {
  isLoading: boolean;
  className: string;
}

class LoadingService {
  private isLoadingSubject = new BehaviorSubject<LoadingEntity>({
    isLoading: false,
    className: ''
  });

  get isLoading$() {
    return this.isLoadingSubject.asObservable().pipe(debounceTime(300));
  }

  setInitLoading(isLoading: boolean) {
    this.isLoadingSubject.next({
      isLoading,
      className: ''
    });
  }

  setLoading(isLoading: boolean) {
    this.isLoadingSubject.next({
      isLoading,
      className: 'opacity'
    });
  }
}

const service = new LoadingService();

export default service;
