import { Locale } from '@/enums/lang';

import dayjs from 'dayjs';

interface INewsDetail {
  tags?: string[];
  publishDate?: string;
  title?: string;
  locale?: string;
  summary?: string;
  content?: Content[];
}

export default class NewsDetail {
  tags: string[] = [];
  publishDate = '';
  title = '';
  summary = '';
  content: Content[] = [];
  locale = Locale.EN;
  constructor(newsDetail: INewsDetail = {}) {
    this.tags = newsDetail.tags || [];
    this.publishDate = newsDetail.publishDate || '';
    this.title = newsDetail.title || '';
    this.summary = newsDetail.summary || '';
    this.content = newsDetail.content || [];

    const locale = newsDetail.locale || Locale.EN;
    const isEN = locale.toLowerCase() === Locale.EN.toLowerCase();
    const isCN = locale.toLowerCase() === Locale.CN.toLowerCase();
    const isKR = locale.toLowerCase() === Locale.KO.toLowerCase();
    this.locale = isEN ? Locale.EN : isCN ? Locale.CN : isKR ? Locale.KO : Locale.EN;
  }

  getPublishDate(format: string) {
    return dayjs(this.publishDate).format(format);
  }
}

export class Content {
  dynamicClass?: 'width-fit' | 'height-fit' | null;

  constructor(
    public image: {
      imageId: string;
      imagePath: string;
    } = { imageId: '', imagePath: '' },
    public paragraph: string = '',
    public blockType: number = 0
  ) {}
}
