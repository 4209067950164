
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormButton extends Vue {
  @Prop() type!: 'primary' | 'secondary' | 'outline' | 'outline2';
  @Prop() icon!: string;
  @Prop() suffixIcon!: string;
  @Prop() text!: string;
  @Prop() hasDetail!: boolean;

  get buttonType() {
    return this.type || 'primary';
  }

  buttonClick() {
    this.$emit('click');
  }
}
