
import Tag from '@/models/tag';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class QuickTag extends Vue {
  @Prop({ default: () => [] }) private data!: Tag[];
  @Prop({ default: () => '' }) private selected!: string;

  click(item: Tag) {
    this.$emit('clickTag', item);
  }
}
