import { TagsResponse } from '@/interfaces/http-response/get-all-tags-with-locale';
import Tag from '@/models/tag';
import { GetAllTagsWithLocale } from '@/services/api';
import { BehaviorSubject } from 'rxjs';
import { TagsAdapter } from './../models/http-response-adapter/get-tags';

class TagService {
  private tags = new BehaviorSubject<Tag[]>([]);
  private tagsError = new BehaviorSubject(false);
  private loadedTags: Array<{ lang: string; tags: Tag[] }> = [];

  get tag$() {
    return this.tags;
  }

  get tagsError$() {
    return this.tagsError;
  }

  async getAllTagsWithLocale(lang: string) {
    if (this.loadedTags.find((x) => x.lang === lang)) {
      this.tags.next(this.loadedTags.find((x) => x.lang === lang)!.tags);
      return;
    }
    try {
      const tagsResponse = await GetAllTagsWithLocale<TagsResponse>(lang);
      const tags = tagsResponse.data.map((x) => TagsAdapter.adapt(x));
      this.loadedTags.push({ lang, tags });
      this.tags.next(tags);
    } catch (error) {
      this.tagsError.next(true);
    }
  }
}

const service = new TagService();

export default service;
