import { GameIcon } from '@/interfaces/http-response/get-game-icon';
import { GetImageUrl } from '@/services/api';
import { BehaviorSubject, Observable } from 'rxjs';

class GameIconService {
  private imageBuilderUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  get imageBuilderUrl(): string {
    return this.imageBuilderUrlSubject.value;
  }

  get imageBuilderUrl$(): Observable<string> {
    return this.imageBuilderUrlSubject.asObservable();
  }

  async getImageBuilderUrl() {
    if (this.imageBuilderUrl === '') {
      const { data } = await GetImageUrl<GameIcon>();
      this.imageBuilderUrlSubject.next(data.imageTemplateUrl);
    }
    return this.imageBuilderUrl;
  }
}

const service = new GameIconService();

export default service;
