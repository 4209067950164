import { Subject } from 'rxjs';

class ScrollService {
  scrollToTopSubject = new Subject();

  get scrollToTop$() {
    return this.scrollToTopSubject.asObservable();
  }

  scrollToTop() {
    this.scrollToTopSubject.next(true);
  }
}

const service = new ScrollService();

export default service;
