
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ContentBanner extends Vue {
  @Prop() tag!: string;
  @Prop() date!: string;
  @Prop() title!: string;
  @Prop() description!: string;
}
