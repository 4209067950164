
import BlockContent from '@/components/ui/blocks-content.vue';
import ContentBanner from '@/components/ui/content-banner.vue';
import ContentDetail from '@/components/ui/content-detail.vue';
import PromotionDetailModel from '@/models/promotion-detail';
import PromotionServices from '@/services/promotion';
import { Subscription } from 'rxjs';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { EventAction, EventCategory, EventLabel } from '../../configs/gtm';

@Component({
  components: {
    BlockContent,
    ContentBanner,
    ContentDetail,
  },
})
export default class PromotionDetail extends Vue {
  promotionDetail: PromotionDetailModel = new PromotionDetailModel();
  subscription: Subscription = new Subscription();

  @Watch('$route', { immediate: true, deep: true })
  private async onUrlChange(newVal: Route) {
    try {
      const promotionDetail = await PromotionServices.getPromotionDetail(this.$route.params.id);
      if (promotionDetail.locale.toLowerCase() === this.$route.params.lang.toLowerCase()) {
        this.promotionDetail = promotionDetail;
        this.promotionsArticlePageView(promotionDetail.title);
      } else {
        throw Error('Localization is not match.');
      }
    } catch (e) {
      console.error(e);
      this.$router.push(`/${this.$route.params.lang}/promotions`);
    }
  }

  private promotionsArticlePageView(title: string) {
    this.$gtm.trackEvent({
      event: `idvPromotionsPageView`,
      category: EventCategory.PromotionsPage,
      action: EventAction.View,
      label: EventLabel.PromotionsArticlePageView(title),
    });
  }

  get promotionDetailBanner() {
    return this.promotionDetail.content.length ? this.promotionDetail.content[0].image.imagePath : '';
  }

  get promotionContent() {
    return this.promotionDetail.content.slice(1);
  }

  private backToList() {
    const tabIndex = this.$route.query.tabIndex;
    this.$router.push({
      path: `/${this.$route.params.lang}/promotions`,
      query: { tabIndex },
    });
  }
}
