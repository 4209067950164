<template>
  <div :class="className">
    <slot />
  </div>
</template>

<script>
import Flickity from 'flickity';
import flickityImagesloaded from 'flickity-imagesloaded';
import 'flickity/dist/flickity.css';

export default {
  props: {
    options: Object,
    ready: {
      type: Boolean,
      default: false,
    },
    className: String,
  },

  mounted() {
    this.init();

    // prevent stop autoPlay from user clicks.
    if (this.$props.options.autoPlay) {
      this.$flickity.on('select', () => {
        setTimeout(() => {
          if (this.$flickity) {
            this.$flickity.playPlayer();
          }
        }, this.$props.options.autoPlay);
      });
      this.$flickity.on('staticClick', () => {
        setTimeout(() => {
          if (this.$flickity) {
            this.$flickity.unpausePlayer();
          }
        }, this.$props.options.autoPlay);
      });
    }
  },

  beforeDestroy() {
    this.$flickity.destroy();
    this.$flickity = null;
  },

  methods: {
    /**
     * Initialize a new flickity and emit init event.
     */
    init() {
      this.$flickity = new Flickity(this.$el, this.options);
      this.$emit('init', this.$flickity);
    },

    /**
     * Return the current flickity instance to access directly
     *
     */
    flickity() {
      return this.$flickity;
    },

    /**
     * Selecting Slides
     */

    /**
     * Select a slide
     *
     */
    select(index, isWrapped, isInstant) {
      this.$flickity.select(index, isWrapped, isInstant);
    },

    /**
     * Change to the next slide
     *
     */
    next(isWrapped, isInstant) {
      this.$flickity.next(isWrapped, isInstant);
    },

    /**
     * Change to the previous slide
     *
     */
    previous(isWrapped, isInstant) {
      this.$flickity.previous(isWrapped, isInstant);
    },

    /**
     * Select a cell
     *
     */
    selectCell(value, isWrapped, isInstant) {
      this.$flickity.selectCell(value, isWrapped, isInstant);
    },

    /**
     * Sizing and Positioning
     */

    /**
     * Trigger a resize event
     */
    resize() {
      this.$flickity.resize();
    },

    /**
     * Trigger a reposition event
     */
    reposition() {
      this.$flickity.reposition();
    },

    /**
     * Adding and removing cells
     */

    /**
     * Prepend elements to flickity
     *
     */
    prepend(elements) {
      this.$flickity.prepend(elements);
    },

    /**
     * Append elements to flickity
     *
     */
    append(elements) {
      this.$flickity.append(elements);
    },

    /**
     * Insert elements at a given index
     *
     */
    insert(elements, index) {
      this.$flickity.insert(elements, index);
    },

    /**
     * Remove elements from flickity
     *
     */
    remove(elements) {
      this.$flickity.remove(elements);
    },

    /**
     * Player
     */

    /**
     * Trigger a playPlayer event
     */
    playPlayer() {
      this.$flickity.playPlayer();
    },

    /**
     * Trigger a stopPlayer event
     */
    stopPlayer() {
      this.$flickity.stopPlayer();
    },

    /**
     * Trigger a pausePlayer event
     */
    pausePlayer() {
      this.$flickity.pausePlayer();
    },

    /**
     * Trigger a unpausePlayer event
     */
    unpausePlayer() {
      this.$flickity.unpausePlayer();
    },

    /**
     * Trigger a rerender event
     */
    rerender() {
      this.$flickity.destroy();
      this.init();
    },

    /**
     * Utilities
     */

    /**
     * Destroy the flickity instance
     */
    destroy() {
      this.$flickity.destroy();
    },

    /**
     * Trigger a rerender event
     */
    reloadCells() {
      this.$flickity.reloadCells();
    },

    /**
     * Get the cell elements
     *
     */
    getCellElements() {
      return this.$flickity.getCellElements();
    },

    /**
     * Return flickity data
     *
     */
    data() {
      return Flickity.data(this.$el);
    },

    /**
     * Events
     */

    /**
     * Attach an event
     *
     */
    on(eventName, listener) {
      this.$flickity.on(eventName, listener);
    },

    /**
     * Remove an event
     *
     */
    off(eventName, listener) {
      this.$flickity.off(eventName, listener);
    },

    /**
     * Attach an event once
     *
     */
    once(eventName, listener) {
      this.$flickity.once(eventName, listener);
    },

    /**
     * Properties
     */

    /**
     * Return the selected element
     *
     */
    selectedElement() {
      return this.$flickity.selectedElement;
    },

    /**
     * Return the selected elements
     *
     */
    selectedElements() {
      return this.$flickity.selectedElements;
    },

    /**
     * Return the selected index
     *
     */
    selectedIndex() {
      return this.$flickity.selectedIndex;
    },

    /**
     * Return the cells
     *
     */
    cells() {
      return this.$flickity.cells;
    },

    /**
     * Return the slides
     *
     */
    slides() {
      return this.$flickity.slides;
    },

    /**
     * Disable dragging of slider
     */
    disableDrag() {
      this.$flickity.options.draggable = false;
      this.$flickity.updateDraggable();
    },

    /**
     * Enable dragging of slider
     */
    enableDrag() {
      this.$flickity.options.draggable = true;
      this.$flickity.updateDraggable();
    },
  },
};
</script>

<style lang="scss">
.flickity-page-dots {
  .dot {
    width: 10px;
    height: 10px;
    background: #8391a5;
    border: solid 1px rgba(255, 255, 255, 0.4);
    margin: 0 5px;
    transition: all 0.5s;
    @include NotMobile {
      &:hover {
        opacity: 0.7;
      }
    }

    &.is-selected {
      background: #4ae6af;
    }
  }
}

.game-detail-slider {
  .flickity-viewport {
    overflow: visible;
  }
  .flickity-button {
    background: transparent;
    svg {
      fill: white;
    }

    &:disabled {
      cursor: auto;
      pointer-events: auto;
    }
  }
  &.hide-button {
    .flickity-button {
      display: none;
    }
  }
}
</style>
