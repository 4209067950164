
import { Dropdown, DropdownItem, DropdownMenu } from 'view-design';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Dropdown,
    DropdownMenu,
    DropdownItem,
  },
})
export default class FormSortby extends Vue {
  @Prop() listItems!: string[];
  @Prop() text!: string;
  private selectedItem = this.listItems[0];

  reset() {
    this.selectedItem = this.listItems[0];
  }

  setSelectedItem(order: number) {
    this.selectedItem = this.listItems[order];
  }

  private itemSelected(item: string) {
    this.selectedItem = item;
    this.$emit('selected', item);
  }
}
