
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface NotificationDetailModalData {
  notificationList: any[];
}

@Component({})
export default class NotificationDetailModal extends Vue {
  private data: NotificationDetailModalData = {
    notificationList: [],
  };
  private index = 0;

  private get current() {
    return this.data.notificationList[this.index] || {};
  }
  private get disabledPrev() {
    return this.index === 0;
  }

  private get disabledNext() {
    return this.index === this.data.notificationList.length - 1;
  }

  private prev() {
    if (this.index > 0) {
      this.index -= 1;
    }
  }

  private next() {
    if (this.index < this.data.notificationList.length - 1) {
      this.index += 1;
    }
  }
}
