
import LoginModal from '@/components/common/modal/login.vue';
import Notification from '@/components/common/notification.vue';
import FormButton from '@/components/ui/form-button.vue';
import clickOutside from '@/directives/clickoutside';
import { Locale } from '@/enums/lang';
import AuthService from '@/services/auth';
import ModalService from '@/services/modal';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component({
  components: {
    Notification,
    FormButton,
  },
  directives: {
    clickOutside,
  },
})
export default class Header extends Vue {
  private readonly pcWidth = 768;
  private isTop = true;
  private isLogin = false;
  private isNotification = false;
  private loginToggleType = {
    drawer: false,
    dropdown: false,
  };
  private isMenuToggle = false;
  private langs = [
    {
      key: Locale.EN,
      value: 'English',
      img: require('@/assets/images/header/ic-en.png'),
    },
    {
      key: Locale.CN,
      value: '简体中文',
      img: require('@/assets/images/header/ic-cn.png'),
    },
  ];
  isHeaderHide = false;
  lastScrollTop = 0;

  get isHomePage() {
    return this.$route.name === 'home';
  }

  get isZH() {
    return this.$i18n.locale === 'zh-CN';
  }

  protected mounted() {
    this.handleWindowResize();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleWindowResize);
    AuthService.isAuthenticated$.subscribe((isLogin) => (this.isLogin = isLogin));
  }
  protected destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleWindowResize);
  }

  private setLang(newLang: string) {
    this.isMenuToggle = false;
    if (newLang === this.$i18n.locale) {
      return;
    }
    this.$i18n.locale = newLang;
    this.setTitle();
    this.$router.push({
      name: this.$route.name || '',
      params: { ...this.$route.params, lang: newLang },
    });
  }

  private handleScroll() {
    const topSize = 1;
    this.isTop = window.pageYOffset < topSize;
    if (this.$route.name !== 'game-detail' && this.isHeaderHide) {
      this.resetScrollState();
    }
    if (this.$route.name === 'game-detail' && this.$screenDetect.isMobile) {
      this.$nextTick(() => {
        const element = this.$refs.header as HTMLElement;
        const current = window.pageYOffset || document.documentElement.scrollTop;
        let isScrollDown = false;
        if (current > this.lastScrollTop) {
          isScrollDown = true;
        }
        this.lastScrollTop = current <= 0 ? 0 : current;
        if (window.scrollY > 610 && isScrollDown) {
          element.classList.add('hide');
          this.isHeaderHide = true;
        } else {
          this.resetScrollState();
        }
      });
    }
  }

  private resetScrollState() {
    const el = this.$refs.header as HTMLElement;
    el.classList.remove('hide');
    this.isHeaderHide = false;
  }

  private setTitle() {
    document.title = this.isZH ? 'MGA 客户中心' : 'MGA Customer Hub';
  }

  private handleWindowResize() {
    const width = window.innerWidth;
    if (width >= this.pcWidth) {
      this.isMenuToggle = false;
    } else {
      this.loginToggleType.dropdown = false;
    }
  }

  private login() {
    if (this.isLogin) {
      const width = window.innerWidth;
      if (width >= this.pcWidth) {
        this.loginToggleType.dropdown = !this.loginToggleType.dropdown;
      }
    } else {
      this.showLoginModal();
    }
  }

  private showLoginModal() {
    this.isMenuToggle = false;
    ModalService.open(LoginModal, { overlayCanceling: false });
  }

  private logout() {
    this.isMenuToggle = false;
    AuthService.logout();
    this.loginToggleType.dropdown = false;
  }

  private onClickoutside() {
    this.loginToggleType.dropdown = false;
  }

  private onSwitch(event: boolean) {
    this.isNotification = event;
  }

  @Watch('$route')
  private onRouteChange(from: Route, to: Route) {
    this.isMenuToggle = false;
  }
}
