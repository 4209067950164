import { Locale } from '@/enums/lang';
import axios from 'axios';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { loadDataFromServer } from './api-init';

Vue.use(VueI18n);

function loadMessages() {
  const context = require.context('../locales', true, /[a-z0-9-_]+\.json$/i);
  const messages = context
    .keys()
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i) ? key.match(/[a-z0-9-_]+/i)![0] : '' }))
    .reduce(
      (messages, { key, locale }) => ({
        ...messages,
        [locale]: context(key),
      }),
      {}
    );
  return messages;
}

export const i18n = new VueI18n({
  locale: Locale.EN,
  fallbackLocale: Locale.EN,
  messages: loadMessages(),
});

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  window.document.querySelector('html')!.setAttribute('lang', lang);
  document.title = lang === 'zh-CN' ? 'MGA 客户中心' : 'MGA Customer Hub';
  loadDataFromServer(lang);
  return lang;
}

export function loadLanguageAsync(lang: string) {
  return Promise.resolve(setI18nLanguage(lang));
}
